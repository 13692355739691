@import "src/assets/styles/colors";

.modal-container{
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #0000001c;
}

 .modal {
    &.Phase4OfferModal {
      color: black;
      max-width: none;
      text-align: left;
      height: 65vh;
      width: 80vw;
      top: 15rem;
      left: 11rem;
      z-index: 9999;
      background-image: url(https://learninglab.sdabocconi.it/files/eurofire_material/phase4OfferFooterImage.png);
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-repeat: no-repeat;
      background-size: cover;

      h1 {
        margin: 0;
        margin-bottom:0.75rem
      }

      .close-container {
        margin-left: 0;
        text-align: left;
        position: relative;

        img.close {
          position: absolute;
          right: 1.6rem;
          top: 0;
          border: 1px solid black;
          padding: 0.5rem;
        }
      }
      
      .content {
        background-color: #ffffff9e;
        width: 70%;
        padding: 2rem 1.15rem;;
        box-sizing: border-box;
      }

      .phase4Offer-popup-other-message {
        margin-bottom: 0.75rem;
      }

      .email-phase4Offer-body,
      .email-phase4Offer-popup-signature {
        font-style: italic;
      }

      .phase4Offer-popup-other-message,
      .email-phase4Offer-body,
      .email-phase4Offer-popup-signature,
      h1 {
        width: 90%;
      }

    }

  }
