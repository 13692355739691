@import "src/assets/styles/colors";

.ThankYou {
  height: calc(100vh - 100px);
  background: radial-gradient(790.87px at 58.69% 39.44%, $color-blue 0%, $color-bocconi-blue 100%);;
  overflow-y: auto;
  text-align: left;
  color: $color-white;
  position: relative;
  padding: 5rem 7rem 7rem 7rem;

  .WelcomeHeader, .ThankYou-message {
    display: inline-block;
    width: 50%;
  }

  .WelcomeHeader {
    vertical-align: top;
  }

  .ThankYou-message {
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-size: 2.5rem;
    line-height: 43px;
    text-align: right;
    font-weight: 100;
    b {
      font-weight: 500;
    }
  }

  .greetings-label {
    font-size: 2.5rem;
  }

  .eurofire-knows-message {

    line-height: 1rem;

    small {
      font-size: 55%;
    }
  
  }


  .report-container, .llabLogo-container {
    text-align: right;
  }

  .report-container {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: 2rem;
  }

  .llabLogo-container {
    position: absolute;
    right: 7rem;
    bottom: 4rem;
  }

  .teacherInfoBoxes-container {
    position: absolute;
    right: 5rem;
    bottom: 10rem;
  }



}

.ThankYouImage {
  position: fixed;
  left: 0;
  width: 40%;
  bottom: 0;
  
}



/* reset css input */

input {
  border:none;
  background-image:none;
  background-color:transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

