/// COLOR RELATED STYLESHEET

$color-dark: #333;
$color-grey: #ccc;
$color-light-grey: #e8e8e8;
$color-dark-grey: #BBBBBB;
$color-off-white: #f9f9f9;
$color-white: #fff;

$color-bocconi-blue: #003a69;
$color-blue: #2764FF;
$color-red: #CF0000;
$color-light-red: #E94040;
$color-pale-red: #FFE5E5;
$color-violet: #6C39D8;
$color-orange: #FF8744;
$color-light-green: #E8FFEE;
$color-green: #39CB85;
$color-dark-green: #0B9B5F;
$color-yellow: #FEC00F;
$color-dark-yellow:#D09D19;

:export {
  colorWhite: $color-white;
}