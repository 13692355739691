.button-empty-blu {
  border: 1px solid #2764FF;
  color: #2764FF;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 0.32rem 1.8rem;
  &:hover{
    background-color: #2764FF;
    color: white;
  }
}
