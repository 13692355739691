@import url('https://fonts.googleapis.com/css?family=Rubik:300,400,500,600,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap');
@import './styles/buttons.scss';
@import "src/assets/styles/colors";

html {
  font-size: 14px !important;

  @media screen and (max-width: 78rem){
    font-size: 11.2px !important;
  }

}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'font-family: 'Roboto', sans-serif;', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media screen and (max-width: 50rem){
    font-size: 11.2px !important;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.admin-title{
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 300;
  line-height: 40px;
  color: $color-blue;
  text-align: left;

  word-wrap: none;
  word-break: keep-all;
  white-space: nowrap;
  width: 250px;
  font-size: 28px;
}

.matrix{
  padding: 0 1.5rem;
  .matrix-row {
    margin-top: .5rem;
    display: grid;
    flex: 1;
    grid-template-columns: 10rem repeat(10, 175px);
    /* grid-template-columns: 10rem repeat(10, minmax(100px, 1fr)); */
    grid-gap: 0.5rem;
    align-items: center;

    .matrix-indexes{
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.1em;
      color: $color-grey;
      position: relative;
      height: 100%;
      .index-left {
        position: absolute;
        left: 0;
        bottom: 0;
      }
      .index-right {
        position: absolute;
        right: 0;
        top: 0;
      }
    }
    .matrix-title {
      background: $color-light-grey;
      padding: 0.8rem 0.5rem;
      border-radius: 0.5rem;
      font-weight: 500;
      text-align: left;
      height: 100%;
      position: relative;
      display: flex;
      cursor: pointer;
      &.active{
        background: $color-blue;
        color: $color-white;
      }

      @media screen and (max-width: 93.75rem){
        font-size: .75rem;
      }
    }
    .matrix-total{
      background: $color-grey;
      padding: 0.8rem 0.5rem;
      border-radius: 0.5rem;
      font-weight: bold;
      text-align: center;
      height: 100%;
      color: $color-dark;
    }
  }
}

.ui.modals {
    z-index: 10000 !important;
}

.ui.popup{
  padding: 0 !important;

  &.visible {
    display: none !important;
  }
}


/// CARD LAYOUT **PLEASE DON'T OVERRIDE**

.card {
  width: 100%;
  height: 100%;
  background: $color-white;
  box-shadow: 0.125rem 0.125rem 1.25rem rgba(0, 0, 0, 0.1);
  border-radius: 0.3125rem;
  margin-top: 1rem;
  max-height: calc(100vh - 230px);
  max-width: calc(100vw - 190px);
}


/* ANIMATIONS */

//FadeLateral
.fadeLateral-enter {
  opacity: 0.01;
  transform: translate3d(100%, 0,0);
}

.fadeLateral-enter.fadeLateral-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
  transform: translate3d(0, 0, 0);
  transition: transform 500ms ease-in;
}

.fadeLateral-leave {
  transform: translate3d(0, 0, 0);
  position: absolute;
  top: 0;
}

.fadeLateral-leave.fadeLateral-leave-active {
  display: none;
  transform: translate3d(-150%, 0, 0);
  transition: transform 600ms ease-in-out;
}

.fadeLateral-appear {
  opacity: 0.01;
  transform: translate3d(100%, 0,0);
}

.fadeLateral-appear.fadeLateral-appear-active {
  opacity: 1;
  transition: opacity .5s ease-in;
  transform: translate3d(0, 0, 0);
  transition: transform 500ms ease-in-out;
}

//Fade Vertical
.fadeOpacity-enter {
  opacity: 0.01;
  //transform: translate3d(0, 100%,0);
}

.fadeOpacity-enter.fadeOpacity-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
  //transform: translate3d(0, 0, 0);
  //transition: transform 500ms ease-in;
}

.fadeOpacity-leave {
 // transform: translate3d(0, 0, 0);
  //position: absolute;
  top: 0;
}

.fadeOpacity-leave.fadeOpacity-leave-active {
  display: none;
  //transform: translate3d(0, -150%, 0);
  //transition: transform 600ms ease-in-out;
}

.fadeOpacity-appear {
  opacity: 0.01;
  //transform: translate3d(0,100%,0);
}

.fadeOpacity-appear.fadeOpacity-appear-active {
  opacity: 1;
  transition: opacity .5s ease-in;
  //transform: translate3d(0, 0, 0);
  //transition: transform 500ms ease-in-out;
}


@media screen and (min-width: 601px) and (max-width: 1199px) {

  .admin-title{
    font-size: 26px;
  }

}
