@import url(https://fonts.googleapis.com/css?family=Rubik:300,400,500,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap);
.button-empty-blu {
  border: 1px solid #2764FF;
  color: #2764FF;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 0.32rem 1.8rem; }
  .button-empty-blu:hover {
    background-color: #2764FF;
    color: white; }

html {
  font-size: 14px !important; }
  @media screen and (max-width: 78rem) {
    html {
      font-size: 11.2px !important; } }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "font-family: " Roboto ", sans-serif;", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  @media screen and (max-width: 50rem) {
    body {
      font-size: 11.2px !important; } }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

.admin-title {
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 300;
  line-height: 40px;
  color: #2764FF;
  text-align: left;
  word-wrap: none;
  word-break: keep-all;
  white-space: nowrap;
  width: 250px;
  font-size: 28px; }

.matrix {
  padding: 0 1.5rem; }
  .matrix .matrix-row {
    margin-top: .5rem;
    display: grid;
    flex: 1 1;
    grid-template-columns: 10rem repeat(10, 175px);
    /* grid-template-columns: 10rem repeat(10, minmax(100px, 1fr)); */
    grid-gap: 0.5rem;
    align-items: center; }
    .matrix .matrix-row .matrix-indexes {
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.1em;
      color: #ccc;
      position: relative;
      height: 100%; }
      .matrix .matrix-row .matrix-indexes .index-left {
        position: absolute;
        left: 0;
        bottom: 0; }
      .matrix .matrix-row .matrix-indexes .index-right {
        position: absolute;
        right: 0;
        top: 0; }
    .matrix .matrix-row .matrix-title {
      background: #e8e8e8;
      padding: 0.8rem 0.5rem;
      border-radius: 0.5rem;
      font-weight: 500;
      text-align: left;
      height: 100%;
      position: relative;
      display: flex;
      cursor: pointer; }
      .matrix .matrix-row .matrix-title.active {
        background: #2764FF;
        color: #fff; }
      @media screen and (max-width: 93.75rem) {
        .matrix .matrix-row .matrix-title {
          font-size: .75rem; } }
    .matrix .matrix-row .matrix-total {
      background: #ccc;
      padding: 0.8rem 0.5rem;
      border-radius: 0.5rem;
      font-weight: bold;
      text-align: center;
      height: 100%;
      color: #333; }

.ui.modals {
  z-index: 10000 !important; }

.ui.popup {
  padding: 0 !important; }
  .ui.popup.visible {
    display: none !important; }

.card {
  width: 100%;
  height: 100%;
  background: #fff;
  box-shadow: 0.125rem 0.125rem 1.25rem rgba(0, 0, 0, 0.1);
  border-radius: 0.3125rem;
  margin-top: 1rem;
  max-height: calc(100vh - 230px);
  max-width: calc(100vw - 190px); }

/* ANIMATIONS */
.fadeLateral-enter {
  opacity: 0.01;
  -webkit-transform: translate3d(100%, 0, 0);
          transform: translate3d(100%, 0, 0); }

.fadeLateral-enter.fadeLateral-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  transition: -webkit-transform 500ms ease-in;
  transition: transform 500ms ease-in;
  transition: transform 500ms ease-in, -webkit-transform 500ms ease-in; }

.fadeLateral-leave {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  position: absolute;
  top: 0; }

.fadeLateral-leave.fadeLateral-leave-active {
  display: none;
  -webkit-transform: translate3d(-150%, 0, 0);
          transform: translate3d(-150%, 0, 0);
  transition: -webkit-transform 600ms ease-in-out;
  transition: transform 600ms ease-in-out;
  transition: transform 600ms ease-in-out, -webkit-transform 600ms ease-in-out; }

.fadeLateral-appear {
  opacity: 0.01;
  -webkit-transform: translate3d(100%, 0, 0);
          transform: translate3d(100%, 0, 0); }

.fadeLateral-appear.fadeLateral-appear-active {
  opacity: 1;
  transition: opacity .5s ease-in;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  transition: -webkit-transform 500ms ease-in-out;
  transition: transform 500ms ease-in-out;
  transition: transform 500ms ease-in-out, -webkit-transform 500ms ease-in-out; }

.fadeOpacity-enter {
  opacity: 0.01; }

.fadeOpacity-enter.fadeOpacity-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in; }

.fadeOpacity-leave {
  top: 0; }

.fadeOpacity-leave.fadeOpacity-leave-active {
  display: none; }

.fadeOpacity-appear {
  opacity: 0.01; }

.fadeOpacity-appear.fadeOpacity-appear-active {
  opacity: 1;
  transition: opacity .5s ease-in; }

@media screen and (min-width: 601px) and (max-width: 1199px) {
  .admin-title {
    font-size: 26px; } }

.App {
  text-align: center;
  font-family: 'Rubik', 'sans-serif';
  font-weight: 300;
  height: 100vh;
}


.AdminHeader {
  font-family: 'Rubik', sans-serif;
  color: #2764FF;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  flex-direction: row;
  text-align: right;
  align-items: center;
  justify-content: flex-end; }
  .AdminHeader .button-empty-blu {
    margin: 0 1.9rem;
    text-transform: uppercase;
    font-style: normal;
    font-size: 14px;
    padding: 0 16px;
    line-height: 29px; }
  .AdminHeader .info {
    font-family: 'Rubik', sans-serif;
    font-style: italic;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    text-align: right; }
    .AdminHeader .info .team {
      font-weight: 700;
      margin-left: 1rem; }
  .AdminHeader .logout {
    margin-left: 1.9rem;
    text-transform: uppercase; }
  .AdminHeader .langs {
    display: inline-flex;
    color: #BBBBBB;
    align-items: center;
    font-size: 14px; }
    .AdminHeader .langs .lang {
      padding: 0.5em;
      cursor: pointer; }
      .AdminHeader .langs .lang.active {
        color: #2764FF;
        font-weight: 500; }
  .AdminHeader img.bocconi {
    min-width: 300px; }
  .AdminHeader .bocconi {
    margin-left: 2.5rem; }
  .AdminHeader .title {
    font-family: 'Roboto', sans-serif;
    font-style: italic;
    font-weight: 700;
    font-size: 40px;
    line-height: 47px;
    margin-left: 0.5rem; }

.AdminMain {
  display: flex;
  flex-grow: 1;
  margin-top: 2rem;
  display: -webkit-flex;
  -webkit-flex-grow: 1; }

@media screen and (min-width: 601px) and (max-width: 1199px) {
  .AdminMain {
    margin-bottom: 2rem; } }

.AdminMenu {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: 1.5rem;
  flex-basis: 64px;
  display: -webkit-flex;
  -webkit-flex-basis: 64px; }
  .AdminMenu .menu-item {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 1px 1px 40px rgba(0, 0, 0, 0.2);
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 0.5rem;
    margin-right: 1rem;
    background: #fff;
    color: #ccc; }
    .AdminMenu .menu-item.first-element {
      margin-bottom: 2rem; }
    .AdminMenu .menu-item .image.on {
      display: none; }
    .AdminMenu .menu-item .image.off {
      display: block; }
    .AdminMenu .menu-item.active {
      background: #2764FF;
      color: #fff; }
      .AdminMenu .menu-item.active .image.on {
        display: block; }
      .AdminMenu .menu-item.active .image.off {
        display: none; }

.TeamsMenu {
  display: flex; }
  .TeamsMenu .menu {
    margin-left: 6rem;
    display: flex;
    flex: 1 1;
    position: relative;
    padding: 0 3rem;
    align-items: center; }
    .TeamsMenu .menu .menu-item-container {
      position: relative; }
    .TeamsMenu .menu .onboard-link {
      position: absolute;
      top: 7.5px;
      right: 21px;
      width: 25px;
      height: 25px; }
      .TeamsMenu .menu .onboard-link > img {
        width: 17px;
        height: 17px;
        margin: 4px; }
      .TeamsMenu .menu .onboard-link:hover {
        background: #ffffff2e;
        border-radius: 4px; }
    .TeamsMenu .menu .menu-item {
      text-align: left;
      box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      /* min-width: 19rem; */
      font-family: 'Rubik', sans-serif;
      font-style: normal;
      font-weight: 500;
      color: #ccc;
      background: #fff;
      margin-right: 1rem;
      padding: 0.75rem 1rem; }
      .TeamsMenu .menu .menu-item.initialized {
        color: #39CB85; }
      .TeamsMenu .menu .menu-item.global {
        background-color: #cccccc;
        color: white; }
      .TeamsMenu .menu .menu-item.active {
        color: #fff;
        background: #2764FF; }
      @media screen and (max-width: 93.75rem) {
        .TeamsMenu .menu .menu-item {
          font-size: .75rem; } }
    .TeamsMenu .menu img {
      width: 1.5rem;
      cursor: pointer;
      position: absolute;
      left: 0; }
      .TeamsMenu .menu img.inverted {
        -webkit-transform: rotateZ(180deg);
        transform: rotateZ(180deg);
        right: 0;
        left: unset; }

@media screen and (min-width: 601px) and (max-width: 1199px) {
  .TeamsMenu {
    display: contents !important; }
    .TeamsMenu .menu {
      margin-left: 0 !important;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0 2rem !important;
      max-height: 5rem; } }
    @media screen and (min-width: 601px) and (max-width: 1199px) and (min-width: 601px) and (max-width: 800px) {
      .TeamsMenu .menu .menu-item-container {
        width: 13rem !important; } }

@media screen and (min-width: 601px) and (max-width: 1199px) {
      .TeamsMenu .menu .onboard-link {
        top: 7.5px !important;
        right: 12px !important;
        width: 22px !important;
        height: 22px !important; }
        .TeamsMenu .menu .onboard-link > img {
          width: 14px !important;
          height: 14px !important;
          margin: 4px !important; } }
      @media screen and (min-width: 601px) and (max-width: 1199px) and (min-width: 601px) and (max-width: 800px) {
        .TeamsMenu .menu .onboard-link > img {
          width: 12px !important;
          height: 12px !important;
          margin: 3px !important; } }


.AdminMatrix {
  padding-top: 1rem; }
  .AdminMatrix.card {
    overflow-y: auto; }
  .AdminMatrix.matrix .matrix-row .index-left {
    text-transform: uppercase; }
  .AdminMatrix.matrix .matrix-row .index-right {
    text-transform: uppercase; }
  .AdminMatrix.matrix .matrix-row.secondary-row {
    margin-top: .25rem; }
  .AdminMatrix.matrix .matrix-row.scenario-retries-totals-row {
    font-weight: 300;
    font-size: 13px;
    line-height: 13px;
    color: #6C39D8;
    text-align: center;
    font-family: Rubik;
    font-style: normal; }
    .AdminMatrix.matrix .matrix-row.scenario-retries-totals-row div:not(:first-child) {
      border-bottom: 1px solid #6C39D8;
      padding-bottom: 0.4375rem;
      position: relative; }
      .AdminMatrix.matrix .matrix-row.scenario-retries-totals-row div:not(:first-child):not(:last-child):after {
        display: block;
        content: " ";
        width: 20px;
        height: 0.0625rem;
        background: #6C39D8;
        position: absolute;
        bottom: -0.0625rem;
        right: -10px; }
  .AdminMatrix.matrix .matrix-row.retries-totals-row div {
    grid-column: 7;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 13px;
    color: #6C39D8;
    display: inline-flex;
    align-content: flex-end;
    justify-content: flex-end;
    align-items: center;
    justify-items: center;
    margin-bottom: .5rem; }
    .AdminMatrix.matrix .matrix-row.retries-totals-row div b {
      height: 2.5rem;
      width: 2.5rem;
      background-color: #6C39D8;
      border-radius: 8px;
      color: #fff;
      display: inline-flex;
      align-content: center;
      justify-content: center;
      align-items: center;
      justify-items: center;
      margin-left: .25rem; }
  .AdminMatrix.matrix .matrix-row .scenario-retries {
    display: inline-flex;
    align-content: flex-start;
    justify-content: center;
    align-items: flex-start;
    font-weight: 300;
    font-size: 13px;
    line-height: 13px;
    color: #999999; }
    .AdminMatrix.matrix .matrix-row .scenario-retries b {
      font-weight: bold;
      margin-left: .1rem; }
  .AdminMatrix .notes-active {
    border-bottom: 5px solid #FF8744;
    border-radius: 2.5px; }
  .AdminMatrix .notes-active-reverse {
    border-left: 5px solid #FF8744;
    border-radius: 2.5px;
    height: 100%;
    position: absolute;
    top: 0;
    left: -10px; }

@media screen and (min-width: 601px) and (max-width: 1199px) {
  .AdminMatrix.card {
    min-width: 100%;
    height: 50vh; }
  .AdminMatrix.matrix .matrix-row.scenario-retries-totals-row {
    line-height: 30px; } }

.AdminKpi {
  display: flex;
  flex-direction: column;
  height: 100%; }
  .AdminKpi .ui.input {
    height: 100%; }
  .AdminKpi .label {
    text-align: left;
    padding-bottom: 0.4rem; }
  .AdminKpi .input-ele {
    border-left: 1px solid #E8FFEE;
    display: inline-flex; }
  .AdminKpi .output {
    font-weight: bold; }
  .AdminKpi.correct .output {
    color: #39CB85; }
  .AdminKpi.wrong .output {
    color: #E94040; }

.AdminModal {
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(51, 51, 51, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 2001; }
  .AdminModal > .content {
    display: flex; }
  .AdminModal .AdminKpis {
    margin: 0; }

.SquareButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  min-width: 3rem;
  width: 3rem;
  height: 3rem;
  margin: 1.5rem; }

.SectionTitle {
  display: flex;
  align-items: center;
  align-self: flex-start;
  width: 100%; }
  .SectionTitle .title {
    display: flex;
    flex-grow: 1;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    margin: 0 0 0 0.5rem;
    padding: 0.3em 0.3em 0.3em 0;
    padding-top: 1rem;
    padding-right: 1rem;
    color: #333;
    text-align: left; }

@media screen and (min-width: 601px) and (max-width: 1199px) {
  .SectionTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 0.5;
    padding-bottom: 2.5rem; } }
  @media screen and (min-width: 601px) and (max-width: 1199px) and (max-height: 600px) {
    .SectionTitle {
      width: 100%;
      height: auto; } }

@media screen and (min-width: 601px) and (max-width: 1199px) {
    .SectionTitle .title {
      font-size: 12px;
      line-height: 16px; } }

.AdminNotes {
  min-width: 600px;
  max-width: 55vw;
  height: 30vh;
  background: #fff;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px; }
  .AdminNotes .content {
    padding: 2rem;
    white-space: pre-line;
    text-align: left; }
  .AdminNotes .SectionTitle .title {
    padding: 0.3rem 1rem;
    text-align: left; }

@media screen and (min-width: 601px) and (max-width: 1199px) {
  .AdminNotes {
    width: 100%;
    height: 20%;
    min-width: auto;
    max-width: none; }
    .AdminNotes .SectionTitle {
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      width: 100% !important;
      height: 100% !important;
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important; } }

.Card {
  width: 100%;
  height: 100%;
  background: #fff;
  box-shadow: 0.125rem 0.125rem 1.25rem rgba(0, 0, 0, 0.1);
  border-radius: 0.3125rem;
  overflow: auto; }

@media screen and (min-width: 601px) and (max-width: 1199px) {
  .Card .SectionTitle {
    width: 100%; } }

.modal-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #0000001c; }

.modal {
  font-family: 'Rubik', 'sans-serif';
  background: #fff;
  box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  position: absolute;
  padding: .5rem 2rem 2rem 2rem;
  bottom: 2rem;
  left: 25rem;
  display: block !important;
  max-width: 32rem; }
  .modal .close-container {
    margin-left: 28.5em;
    text-align: right; }
    .modal .close-container img {
      cursor: pointer; }
  .modal span {
    margin-bottom: 0.5rem; }
  .modal .button-empty-white {
    margin-top: 1.5rem;
    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.15);
    border: 0;
    cursor: pointer; }

.WhiteButton .button-empty-white {
  font-family: 'Rubik', 'sans-serif';
  font-style: normal;
  font-weight: 300;
  font-size: 1.5rem;
  line-height: 38px;
  text-align: center;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  border: 1px solid #333;
  border-radius: 4px;
  padding: 0rem 3rem;
  height: 40px;
  color: #fff;
  border: 1px solid #39CB85;
  cursor: pointer; }

.WhiteButton input {
  font-family: 'Rubik', 'sans-serif';
  font-weight: 500;
  color: #fff;
  padding-left: 1.5rem;
  width: 100%; }

.WhiteButton ::-webkit-input-placeholder {
  color: #fff;
  opacity: 1; }

.WhiteButton ::placeholder {
  color: #fff;
  opacity: 1; }

.AdminPanel {
  flex-grow: 1;
  position: relative;
  padding-top: 0.875rem;
  max-width: calc(100vw - 190px);
  max-height: calc(100vh - 225px); }
  .AdminPanel > span {
    display: block;
    height: 100%; }
  .AdminPanel .game {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: -webkit-min-content 1fr;
    grid-template-rows: min-content 1fr;
    grid-row-gap: 0.5rem;
    padding: 0.875rem 0; }
    .AdminPanel .game .chapter-wrapper:nth-child(2) {
      grid-row: 2;
      grid-column: 1; }
    .AdminPanel .game .chapter-wrapper:nth-child(3) {
      grid-row: 1/-1;
      grid-column: 2; }
    .AdminPanel .game .chapter-wrapper:nth-child(4) {
      grid-row: 1/-1;
      grid-column: 3; }
    .AdminPanel .game .chapter-wrapper:nth-child(5) {
      grid-row: 1/-1;
      grid-column: 4; }
    .AdminPanel .game .chapter-wrapper:nth-child(6) {
      grid-row: 1/-1;
      grid-column: 5; }
    .AdminPanel .game .chapter-wrapper:nth-child(7) .chapter {
      margin-right: .7rem; }
    .AdminPanel .game .chapter-wrapper:nth-child(8) {
      grid-row: 2;
      grid-column: 6; }
      .AdminPanel .game .chapter-wrapper:nth-child(8) .chapter {
        margin-right: .7rem; }
  .AdminPanel .chapter {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 0.5rem 0.1rem;
    margin: 0 0 0 .7rem;
    background: #ccc;
    border-radius: 5px;
    font-weight: 400; }
    .AdminPanel .chapter .label {
      font-family: 'Rubik', 'sans-serif';
      font-weight: 500;
      font-size: 0.875rem;
      display: flex;
      text-align: left;
      justify-content: space-between;
      margin-bottom: 0.5rem;
      line-height: 1.15; }
    .AdminPanel .chapter .asset {
      background: #fff;
      padding: 0.5rem;
      margin-bottom: 0.5rem;
      border-radius: 0.25rem;
      display: flex;
      align-content: space-between;
      justify-content: space-between;
      align-items: center;
      justify-items: center;
      color: #CF0000;
      line-height: 1.15; }
      .AdminPanel .chapter .asset a {
        color: #CF0000; }
      .AdminPanel .chapter .asset div:nth-child(1) {
        display: flex;
        align-content: flex-start;
        justify-content: flex-start;
        align-items: center; }
        .AdminPanel .chapter .asset div:nth-child(1) span {
          display: inline-block;
          text-align: left;
          word-wrap: break-word; }
      .AdminPanel .chapter .asset a span {
        font-size: 0.875rem; }
      .AdminPanel .chapter .asset span {
        font-size: 0.875rem; }
      .AdminPanel .chapter .asset .doc-title {
        font-family: 'Rubik', 'sans-serif';
        font-weight: 300;
        font-size: 0.875rem;
        text-align: left;
        width: 8rem;
        margin-right: 1rem; }
      .AdminPanel .chapter .asset .image {
        margin-right: 0.5rem; }
      .AdminPanel .chapter .asset.green {
        color: #39CB85; }
        .AdminPanel .chapter .asset.green div {
          margin-left: .3rem; }
        .AdminPanel .chapter .asset.green span {
          color: #39CB85 !important;
          margin: 0.1rem 0rem 0.1rem 0.2rem; }
      .AdminPanel .chapter .asset.orange {
        color: #FF8744; }
        .AdminPanel .chapter .asset.orange div {
          margin-left: .3rem; }
        .AdminPanel .chapter .asset.orange span {
          color: #FF8744 !important;
          margin: 0.1rem 0rem 0.1rem 0.2rem; }
      .AdminPanel .chapter .asset.blue {
        color: #2764FF;
        cursor: pointer; }
        .AdminPanel .chapter .asset.blue.sended {
          border: 1px solid #2764FF; }
    .AdminPanel .chapter .assets.team-report span {
      margin-right: 1rem; }
    .AdminPanel .chapter.phase4Offer {
      margin-top: .7rem; }
  .AdminPanel .scenarios {
    display: flex;
    flex-direction: column; }
    .AdminPanel .scenarios .scenario {
      background: #e8e8e8;
      display: flex;
      padding: 0.5rem;
      flex-direction: column;
      display: flex;
      margin-top: 0.5rem;
      margin-left: .7rem;
      border-radius: 0.25rem;
      justify-content: space-between;
      font-weight: bold; }
      .AdminPanel .scenarios .scenario .title {
        font-family: 'Rubik', 'sans-serif';
        font-weight: 500;
        font-size: 0.875rem;
        text-align: left; }
      .AdminPanel .scenarios .scenario .elements-container {
        border-radius: 0.5rem;
        display: flex;
        align-content: flex-start;
        justify-content: stretch;
        align-items: center;
        justify-items: center;
        flex-wrap: wrap;
        margin-top: 0.3rem; }
        .AdminPanel .scenarios .scenario .elements-container .element-kpi {
          display: flex;
          align-content: center;
          justify-content: space-between;
          align-items: center;
          justify-items: left;
          background: #fff;
          padding: 0;
          border-radius: 0.25rem;
          margin-right: 0.5rem;
          min-width: 5rem;
          height: 2.125rem;
          padding: 0.7rem;
          cursor: pointer;
          opacity: 0.5;
          transition: opacity 0.15s ease-in-out;
          pointer-events: none; }
          .AdminPanel .scenarios .scenario .elements-container .element-kpi.active {
            opacity: 1;
            pointer-events: auto; }
          .image .AdminPanel .scenarios .scenario .elements-container .element-kpi.kpis {
            margin-right: 1rem; }
          .image .AdminPanel .scenarios .scenario .elements-container .element-kpi.comment {
            margin-right: 1rem; }
          .AdminPanel .scenarios .scenario .elements-container .element-kpi .modal-trigger {
            cursor: pointer; }
        .AdminPanel .scenarios .scenario .elements-container .element-comment {
          display: flex;
          align-content: center;
          justify-content: space-between;
          align-items: center;
          justify-items: left;
          background: #fff;
          padding: 0;
          border-radius: 0.25rem;
          margin-right: 1.5rem;
          min-width: 5rem;
          height: 2.125rem;
          padding: 0.7rem;
          cursor: pointer; }
          .image .AdminPanel .scenarios .scenario .elements-container .element-comment.kpis {
            margin-right: 1rem; }
          .image .AdminPanel .scenarios .scenario .elements-container .element-comment.comment {
            margin-right: 1rem; }
          .AdminPanel .scenarios .scenario .elements-container .element-comment .modal-trigger {
            cursor: pointer; }
        .AdminPanel .scenarios .scenario .elements-container.active .element-kpi {
          opacity: 1;
          pointer-events: auto; }
      .AdminPanel .scenarios .scenario .blank-element {
        width: 20px;
        height: 20px; }
  .AdminPanel .CustomButton {
    position: absolute;
    bottom: 1rem;
    right: 1.5vw;
    z-index: 1001; }
  .AdminPanel .ui.fitted.toggle.checkbox {
    display: inline-flex;
    flex-basis: 50px; }
  .AdminPanel .ui.toggle.checkbox input:checked ~ .box:before,
  .AdminPanel .ui.toggle.checkbox input:checked ~ label:before,
  .AdminPanel .ui.toggle.checkbox input:focus:checked ~ label:before,
  .AdminPanel ui.toggle.checkbox input:focus:checked ~ .box:before {
    background-color: #2764FF !important; }
  .AdminPanel .ui.toggle.checkbox input:checked ~ .box:after,
  .AdminPanel .ui.toggle.checkbox input:checked ~ label:after {
    left: 1.5rem;
    box-shadow: 0 1px 3px 0 rgba(34, 36, 38, 0.5); }
  .AdminPanel .ui.toggle.checkbox input ~ .box:after,
  .AdminPanel .ui.toggle.checkbox input ~ label:after {
    box-shadow: 0 1px 3px 0 rgba(34, 36, 38, 0.5); }
  .AdminPanel .ui.toggle.checkbox .box:before,
  .AdminPanel .ui.toggle.checkbox label:before {
    width: 3rem;
    background-color: #BBBBBB !important; }
  .AdminPanel .noClickEvent {
    pointer-events: none; }
  .AdminPanel .modal {
    z-index: 999;
    top: 18rem;
    bottom: auto;
    right: 2rem;
    left: auto; }
    .AdminPanel .modal .button-empty-white {
      color: #39CB85; }

@media screen and (min-width: 601px) and (max-width: 1199px) {
  .AdminPanel {
    max-width: calc(100vw - 165px);
    max-height: 175vh; } }

.AdminPanelProvider {
  display: flex;
  flex-direction: column;
  margin-bottom: -8px; }

.AdminGlobal {
  position: relative; }
  .AdminGlobal .game {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: -webkit-min-content 1fr;
    grid-template-rows: min-content 1fr;
    grid-row-gap: 0.5rem;
    padding: 0.875rem 0;
    overflow: auto;
    position: relative; }
    .AdminGlobal .game .chapter-wrapper:nth-child(2) {
      position: absolute;
      top: 12rem; }
    .AdminGlobal .game .chapter-wrapper:nth-child(3) {
      grid-row: 1/-1;
      grid-column: 2; }
    .AdminGlobal .game .chapter-wrapper:nth-child(4) {
      grid-row: 1/-1;
      grid-column: 3; }
    .AdminGlobal .game .chapter-wrapper:nth-child(5) {
      grid-row: 1/-1;
      grid-column: 4; }
    .AdminGlobal .game .chapter-wrapper:nth-child(6) {
      grid-row: 1/-1;
      grid-column: 5; }
    .AdminGlobal .game .chapter-wrapper:nth-child(7) .chapter {
      margin-right: 1rem; }
    .AdminGlobal .game .edit-kpis-container {
      display: flex; }
      .AdminGlobal .game .edit-kpis-container .edit-kpis-chapter {
        display: flex;
        flex-direction: column;
        padding: 0.5rem 0.5rem 0.1rem;
        margin: 0 0 0 1rem;
        background: #ccc;
        border-radius: 5px;
        font-family: 'Rubik', 'sans-serif';
        font-weight: 500;
        font-size: 0.75rem;
        text-align: left;
        margin-bottom: 0.5rem;
        line-height: 1.15; }
        .AdminGlobal .game .edit-kpis-container .edit-kpis-chapter .edit-kpis-row {
          background: #fff;
          padding: 0.5rem;
          margin-bottom: 0.5rem;
          border-radius: 0.25rem;
          align-items: center;
          justify-items: center;
          line-height: 1.15; }
          .AdminGlobal .game .edit-kpis-container .edit-kpis-chapter .edit-kpis-row .correct-label {
            color: #39CB85;
            font-weight: bold; }
          .AdminGlobal .game .edit-kpis-container .edit-kpis-chapter .edit-kpis-row .table-kpi-text-container {
            margin-top: 1rem; }
          .AdminGlobal .game .edit-kpis-container .edit-kpis-chapter .edit-kpis-row .table-kpi-input-container {
            margin-bottom: 0.5rem; }
          .AdminGlobal .game .edit-kpis-container .edit-kpis-chapter .edit-kpis-row .table-kpi-label {
            text-transform: uppercase; }
            .AdminGlobal .game .edit-kpis-container .edit-kpis-chapter .edit-kpis-row .table-kpi-label .kpiOtherText {
              color: #FF8744; }
          .AdminGlobal .game .edit-kpis-container .edit-kpis-chapter .edit-kpis-row .old-correct-label {
            display: inline-block;
            margin: 0.5rem 0 0.5rem 0; }
          .AdminGlobal .game .edit-kpis-container .edit-kpis-chapter .edit-kpis-row hr {
            border: 1px solid #2764FF; }
          .AdminGlobal .game .edit-kpis-container .edit-kpis-chapter .edit-kpis-row .ui.input {
            margin-bottom: 0.6rem; }
  .AdminGlobal .chapter {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 0.5rem 0.1rem;
    margin: 0 0 0 1rem;
    background: #ccc;
    border-radius: 5px;
    font-weight: bold; }
    .AdminGlobal .chapter .label {
      font-family: 'Rubik', 'sans-serif';
      font-weight: 500;
      font-size: 0.75rem;
      display: flex;
      text-align: left;
      justify-content: space-between;
      margin-bottom: 0.5rem;
      height: 1rem;
      line-height: 1.15; }
    .AdminGlobal .chapter .asset {
      background: #fff;
      padding: 0.5rem;
      margin-bottom: 0.5rem;
      border-radius: 0.25rem;
      display: flex;
      align-content: space-between;
      justify-content: space-between;
      align-items: center;
      justify-items: center;
      color: #CF0000;
      line-height: 1.15; }
      .AdminGlobal .chapter .asset a {
        color: #CF0000; }
      .AdminGlobal .chapter .asset div:nth-child(1) {
        display: flex;
        align-content: flex-start;
        justify-content: flex-start;
        align-items: center; }
        .AdminGlobal .chapter .asset div:nth-child(1) span {
          display: inline-block;
          text-align: left;
          word-wrap: break-word; }
      .AdminGlobal .chapter .asset a span {
        font-size: 0.75rem; }
      .AdminGlobal .chapter .asset span {
        font-size: 0.75rem; }
      .AdminGlobal .chapter .asset .doc-title {
        font-family: 'Rubik', 'sans-serif';
        font-weight: 300;
        font-size: 0.875rem;
        text-align: left;
        width: 8rem;
        margin-right: 1rem; }
      .AdminGlobal .chapter .asset .image {
        margin-right: 0.5rem; }
      .AdminGlobal .chapter .asset.green {
        color: #39CB85; }
        .AdminGlobal .chapter .asset.green span {
          color: #39CB85 !important; }
      .AdminGlobal .chapter .asset.orange {
        color: #FF8744; }
        .AdminGlobal .chapter .asset.orange span {
          color: #FF8744 !important; }
      .AdminGlobal .chapter .asset.blue {
        color: #2764FF;
        cursor: pointer; }
        .AdminGlobal .chapter .asset.blue.sended {
          border: 1px solid #2764FF; }
    .AdminGlobal .chapter .assets.team-report span {
      margin-right: 1rem; }
  .AdminGlobal .scenarios {
    display: flex;
    flex-direction: column; }
    .AdminGlobal .scenarios .scenario {
      background: #e8e8e8;
      display: flex;
      padding: 0.5rem;
      flex-direction: column;
      display: flex;
      margin-top: 0.5rem;
      margin-left: 1rem;
      border-radius: 0.25rem;
      justify-content: space-between;
      font-weight: bold; }
      .AdminGlobal .scenarios .scenario .title {
        font-family: 'Rubik', 'sans-serif';
        font-weight: 500;
        font-size: 0.75rem;
        text-align: left; }
      .AdminGlobal .scenarios .scenario .elements-container {
        border-radius: 0.5rem;
        display: flex;
        align-content: flex-start;
        justify-content: stretch;
        align-items: center;
        justify-items: center;
        flex-wrap: wrap;
        margin-top: 0.3rem; }
        .AdminGlobal .scenarios .scenario .elements-container .element-kpi {
          display: flex;
          align-content: center;
          justify-content: space-between;
          align-items: center;
          justify-items: left;
          background: #fff;
          padding: 0;
          border-radius: 0.25rem;
          margin-right: 0.5rem;
          min-width: 5rem;
          height: 2.125rem;
          padding: 0.7rem;
          /* cursor: pointer; */
          opacity: 0.5;
          transition: opacity 0.15s ease-in-out;
          pointer-events: none; }
          .AdminGlobal .scenarios .scenario .elements-container .element-kpi.active {
            opacity: 1;
            pointer-events: auto; }
          .image .AdminGlobal .scenarios .scenario .elements-container .element-kpi.kpis {
            margin-right: 1rem; }
          .image .AdminGlobal .scenarios .scenario .elements-container .element-kpi.comment {
            margin-right: 1rem; }
        .AdminGlobal .scenarios .scenario .elements-container .element-comment {
          display: flex;
          align-content: center;
          justify-content: space-between;
          align-items: center;
          justify-items: left;
          background: #fff;
          padding: 0;
          border-radius: 0.25rem;
          margin-right: 1.5rem;
          min-width: 5rem;
          height: 2.125rem;
          padding: 0.7rem; }
          .image .AdminGlobal .scenarios .scenario .elements-container .element-comment.kpis {
            margin-right: 1rem; }
          .image .AdminGlobal .scenarios .scenario .elements-container .element-comment.comment {
            margin-right: 1rem; }
        .AdminGlobal .scenarios .scenario .elements-container.active .element-kpi {
          opacity: 1;
          pointer-events: auto; }
      .AdminGlobal .scenarios .scenario .blank-element {
        width: 20px;
        height: 20px; }
  .AdminGlobal .CustomButton {
    position: absolute;
    bottom: 1rem;
    right: 1.5vw;
    z-index: 1001; }
  .AdminGlobal .ui.fitted.toggle.checkbox {
    display: inline-flex; }
  .AdminGlobal .ui.toggle.checkbox input:checked ~ .box:before,
  .AdminGlobal .ui.toggle.checkbox input:checked ~ label:before,
  .AdminGlobal .ui.toggle.checkbox input:focus:checked ~ label:before,
  .AdminGlobal ui.toggle.checkbox input:focus:checked ~ .box:before {
    background-color: #2764FF !important; }
  .AdminGlobal .ui.toggle.checkbox input:checked ~ .box:after,
  .AdminGlobal .ui.toggle.checkbox input:checked ~ label:after {
    left: 1.5rem;
    box-shadow: 0 1px 3px 0 rgba(34, 36, 38, 0.5); }
  .AdminGlobal .ui.toggle.checkbox input ~ .box:after,
  .AdminGlobal .ui.toggle.checkbox input ~ label:after {
    box-shadow: 0 1px 3px 0 rgba(34, 36, 38, 0.5); }
  .AdminGlobal .ui.toggle.checkbox .box:before,
  .AdminGlobal .ui.toggle.checkbox label:before {
    width: 3rem;
    background-color: #BBBBBB !important; }
  .AdminGlobal .noClickEvent {
    pointer-events: none; }
  .AdminGlobal .modal {
    z-index: 999;
    top: 8rem;
    bottom: auto;
    right: 31rem;
    left: auto; }
    .AdminGlobal .modal .button-empty-white {
      color: #39CB85; }

.AdminOnboarding {
  margin-top: 1rem;
  flex-grow: 1;
  flex-basis: 10px;
  max-height: calc(100vh - 225px); }
  .AdminOnboarding .Card {
    padding: 1.57rem 3.75rem;
    overflow-y: auto;
    height: auto;
    max-width: calc(100vw - 160px); }
  .AdminOnboarding h2 {
    font-family: 'Rubik', 'sans-serif';
    color: #2764FF;
    text-align: left;
    font-weight: 300;
    font-size: 1.875rem;
    margin: 2rem 0 1rem 0; }
  .AdminOnboarding .members-container h4 {
    text-align: left; }
  .AdminOnboarding .WhiteButton {
    margin-top: 3rem; }
  .AdminOnboarding div.add-row-members {
    color: #39CB85;
    font-family: 'Rubik', 'sans-serif';
    font-weight: 500;
    font-size: 2rem;
    background-color: rgba(57, 203, 133, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    border-radius: 4px;
    cursor: pointer; }
  .AdminOnboarding .validation-message {
    text-align: left;
    color: #CF0000;
    height: auto;
    padding: 0.5rem;
    margin-bottom: 2rem; }
  .AdminOnboarding div.remove-row-members {
    color: #CF0000;
    font-family: 'Rubik', 'sans-serif';
    font-weight: 500;
    font-size: 2rem;
    background-color: rgba(207, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 3rem;
    width: 3rem;
    height: 3rem;
    border-radius: 4px;
    cursor: pointer; }
  .AdminOnboarding .ui.large.label {
    margin-right: 0.5rem;
    margin-left: 1rem; }
  .AdminOnboarding .button-empty-white {
    color: #39CB85; }
    .AdminOnboarding .button-empty-white.disabled {
      border-color: #ccc;
      color: #ccc;
      cursor: not-allowed; }
  .AdminOnboarding .grid-container .members-container {
    overflow-x: auto;
    max-width: 100%;
    padding-bottom: 3rem; }
  .AdminOnboarding .grid-container .grid-item {
    padding: 0.5em;
    text-align: left;
    display: flex;
    align-items: center;
    /* .third:nth-of-type(1){
        .label{
          width: 30%;
        }
      }
  
      .third:nth-of-type(2){
        .label{
          width: 30%;
        }
      } */ }
    .AdminOnboarding .grid-container .grid-item b {
      font-family: 'Rubik', 'sans-serif';
      color: #333;
      font-weight: 500;
      font-size: 1.6rem;
      width: 45px; }
    .AdminOnboarding .grid-container .grid-item .third {
      width: 33%;
      display: flex;
      align-items: center;
      justify-content: flex-start; }
      .AdminOnboarding .grid-container .grid-item .third .label {
        width: 6rem;
        justify-content: center; }
    .AdminOnboarding .grid-container .grid-item .third:nth-of-type(3) input {
      margin-right: 2rem; }
  .AdminOnboarding .grid-container input {
    border: 1px solid #ccc;
    box-sizing: border-box;
    border-radius: 4px;
    height: 3rem;
    padding: 0 .7rem;
    font-family: 'Rubik', 'sans-serif';
    color: #333;
    font-weight: 300;
    font-size: 1.125rem; }
    .AdminOnboarding .grid-container input.invalid {
      border-color: #CF0000; }
  .AdminOnboarding .grid-container .label {
    height: 3rem;
    line-height: 1.7rem;
    color: #e8e8e8;
    font-family: 'Rubik', 'sans-serif';
    color: rgba(51, 51, 51, 0.8);
    font-weight: 500;
    font-size: 0.875rem;
    padding: 0.75em;
    text-align: left;
    display: flex; }
  .AdminOnboarding .grid-container .confirm-container {
    position: relative; }
  .AdminOnboarding .button-empty-white.disabled {
    border-color: #ccc;
    color: #ccc;
    cursor: not-allowed; }
  .AdminOnboarding .modal {
    font-family: 'Rubik', 'sans-serif';
    background: #fff;
    box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    position: absolute;
    padding: .5rem 2rem 2rem 2rem;
    bottom: 2rem;
    left: 25rem;
    max-width: 32rem; }
    .AdminOnboarding .modal .close-container {
      margin-left: 28.5em;
      text-align: right; }
      .AdminOnboarding .modal .close-container img {
        cursor: pointer; }
    .AdminOnboarding .modal span {
      margin-bottom: 0.5rem; }
    .AdminOnboarding .modal .button-empty-white {
      margin-top: 1.5rem;
      box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.15);
      border: 0;
      cursor: pointer; }

@media screen and (min-width: 601px) and (max-width: 1199px) {
  .AdminOnboarding .Card .grid-container .third {
    width: 100%; } }

@media screen and (max-height: 600px) {
  .AdminOnboarding {
    max-height: none; }
    .AdminOnboarding .Card {
      height: auto; }
    .AdminOnboarding .grid-container .members-container {
      overflow: auto;
      height: auto; } }

.AdminTimer {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .AdminTimer .timer {
    background: #fff;
    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    width: 11rem;
    text-align: center;
    height: 100%;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    color: #2764FF;
    padding: .5rem; }

@media screen and (min-width: 601px) and (max-width: 1199px) {
  .AdminTimer .timer {
    font-size: 18px;
    line-height: 28px; } }

.AdminTimeline {
  overflow-x: auto;
  height: 100%;
  border-radius: 0.3125rem;
  padding-top: 0.875rem;
  max-width: calc(100vw - 190px); }
  .AdminTimeline .Card {
    padding: 1.5rem; }
  .AdminTimeline .matrix {
    padding-top: 1rem;
    padding-bottom: 1rem; }
    .AdminTimeline .matrix .matrix-row {
      grid-template-columns: 10rem repeat(4, 1fr) minmax(auto, 5rem); }
      .AdminTimeline .matrix .matrix-row .matrix-title {
        cursor: text; }
      .AdminTimeline .matrix .matrix-row:first-child .matrix-title {
        position: relative; }
      .AdminTimeline .matrix .matrix-row .chapter {
        padding: 0.8rem 0; }
      .AdminTimeline .matrix .matrix-row .chapter.completed {
        position: relative;
        border-radius: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #0B9B5F; }
        .AdminTimeline .matrix .matrix-row .chapter.completed .timeline {
          background: #39CB85;
          border-radius: 5px;
          position: absolute;
          height: 100%; }
      .AdminTimeline .matrix .matrix-row .chapter.active {
        background: #FEC00F;
        border-radius: 5px;
        align-items: center;
        display: flex;
        justify-content: space-between; }
        .AdminTimeline .matrix .matrix-row .chapter.active .active-at-label {
          margin: 0 0.5rem; }
    .AdminTimeline .matrix .matrix-total.tot {
      text-transform: uppercase; }
  .AdminTimeline .hours-label {
    font-weight: 500;
    font-size: 13px;
    line-height: 14px;
    z-index: 1;
    margin: 0 0.5rem;
    color: rgba(51, 51, 51, 0.4); }
    .AdminTimeline .hours-label.default {
      color: rgba(51, 51, 51, 0); }
  .AdminTimeline .scenarios-label {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    z-index: 1;
    color: rgba(51, 51, 51, 0.4); }
  .AdminTimeline .index-left {
    text-transform: uppercase; }
  .AdminTimeline .index-right {
    text-transform: uppercase; }

@media screen and (min-width: 601px) and (max-width: 1199px) {
  .AdminTimeline {
    max-width: calc(100vw - 165px); }
    .AdminTimeline .matrix .matrix-row {
      grid-template-columns: 10rem repeat(10, 170px); } }

.AdminTimelineProvider {
  display: flex;
  flex-direction: column;
  height: 100%;
  -webkit-display: flex; }
  .AdminTimelineProvider > span {
    flex-grow: 1;
    flex-basis: 20px;
    min-height: 20px;
    -webkit-flex-grow: 1;
    -webkit-flex-basis: 20px; }

.AdminOverviewProvider {
  display: flex;
  flex-direction: column;
  height: 100%; }
  .AdminOverviewProvider > span {
    flex-grow: 1;
    flex-basis: 20px;
    min-height: 20px; }

@media screen and (min-width: 601px) and (max-width: 1199px) {
  .AdminOverviewProvider {
    height: 100vh; } }

.AdminChaptersMenu {
  display: flex; }
  .AdminChaptersMenu .title {
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 28px;
    line-height: 33px;
    color: #2764FF; }
  .AdminChaptersMenu .menu {
    display: flex; }
    .AdminChaptersMenu .menu .menu-item {
      text-align: left;
      box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      font-family: 'Rubik', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #ccc;
      background: #fff;
      margin-right: 1rem;
      min-width: 13rem;
      min-height: 2.875rem;
      padding: 0.75rem 1rem; }
      .AdminChaptersMenu .menu .menu-item.active {
        color: #fff;
        background: #2764FF; }
      @media screen and (max-width: 93.75rem) {
        .AdminChaptersMenu .menu .menu-item {
          font-size: .75rem; } }

@media screen and (min-width: 601px) and (max-width: 1199px) {
  .AdminChaptersMenu {
    display: contents; }
    .AdminChaptersMenu .menu {
      margin-left: 0;
      margin-top: 1rem; }
      .AdminChaptersMenu .menu .menu-item {
        min-width: 9.25rem; } }

.AdminOverviewTable {
  overflow-x: auto;
  height: 100%;
  border-radius: 0.3125rem;
  padding-top: 0.875rem;
  max-width: calc(100vw - 200px); }
  .AdminOverviewTable .Card {
    padding: 1.5rem; }
  .AdminOverviewTable .matrix {
    padding: 0; }
    .AdminOverviewTable .matrix .matrix-title {
      cursor: text; }
    .AdminOverviewTable .matrix .matrix-cell {
      position: relative;
      display: flex;
      flex-direction: row;
      align-content: space-between;
      justify-content: space-between;
      align-items: center;
      justify-items: center;
      height: 100%; }
    .AdminOverviewTable .matrix .index-left {
      text-transform: uppercase; }
    .AdminOverviewTable .matrix .index-right {
      text-transform: uppercase; }

@media screen and (min-width: 601px) and (max-width: 1199px) {
  .AdminOverviewTable {
    max-width: calc(100vw - 150px); } }

.AdminOverviewProgressBar {
  background: #fff;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  display: flex;
  flex-grow: 1;
  height: 100%;
  width: 100%;
  height: 2.5rem;
  position: relative;
  margin-right: 0.3rem;
  display: inline-flex;
  align-content: flex-end;
  justify-content: flex-end;
  align-items: center;
  justify-items: center;
  flex-grow: 1;
  cursor: pointer;
  color: #333; }
  .AdminOverviewProgressBar div {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 50%;
    border-radius: 0.3125rem;
    display: inline-flex;
    justify-content: flex-end;
    align-items: center; }
    .AdminOverviewProgressBar div.WrongValueBar {
      background: #FEC00F; }
    .AdminOverviewProgressBar div.CorrectValueBar {
      background: #39CB85; }
  .AdminOverviewProgressBar span, .AdminOverviewProgressBar label {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: right;
    color: #fff;
    margin-right: 0.6875rem; }
  .AdminOverviewProgressBar label {
    color: #e8e8e8; }

.AdminOverviewModalButton {
  width: 2.5rem;
  height: 2.5rem;
  background: #FF8744;
  box-shadow: 0.0625rem 0.0625rem 0.5rem rgba(0, 0, 0, 0.15);
  border-radius: 0.3125rem;
  display: inline-flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  justify-items: center;
  flex-grow: 1;
  border: none; }
  .AdminOverviewModalButton:disabled {
    background: #fff;
    cursor: not-allowed; }

.AdminProvider {
  display: flex;
  flex-direction: column;
  margin-bottom: -8px; }

.TeamsMenu {
  display: flex; }
  .TeamsMenu .menu {
    margin-left: 6rem;
    display: flex;
    flex: 1 1;
    position: relative;
    padding: 0 3rem;
    align-items: center; }
    .TeamsMenu .menu .menu-item-container {
      position: relative; }
    .TeamsMenu .menu .onboard-link {
      position: absolute;
      top: 7.5px;
      right: 21px;
      width: 25px;
      height: 25px; }
      .TeamsMenu .menu .onboard-link > img {
        width: 17px;
        height: 17px;
        margin: 4px; }
      .TeamsMenu .menu .onboard-link:hover {
        background: #ffffff2e;
        border-radius: 4px; }
    .TeamsMenu .menu .menu-item {
      text-align: left;
      box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      /* min-width: 19rem; */
      font-family: 'Rubik', sans-serif;
      font-style: normal;
      font-weight: 500;
      color: #ccc;
      background: #fff;
      margin-right: 1rem;
      padding: 0.75rem 1rem; }
      .TeamsMenu .menu .menu-item.initialized {
        color: #39CB85; }
      .TeamsMenu .menu .menu-item.global {
        background-color: #cccccc;
        color: white; }
      .TeamsMenu .menu .menu-item.active {
        color: #fff;
        background: #2764FF; }
      @media screen and (max-width: 93.75rem) {
        .TeamsMenu .menu .menu-item {
          font-size: .75rem; } }
    .TeamsMenu .menu img {
      width: 1.5rem;
      cursor: pointer;
      position: absolute;
      left: 0; }
      .TeamsMenu .menu img.inverted {
        -webkit-transform: rotateZ(180deg);
        transform: rotateZ(180deg);
        right: 0;
        left: unset; }

.AdminSessions {
  flex-grow: 1;
  padding-top: 0.875rem;
  max-width: calc(100vw - 190px);
  height: auto !important;
  display: flex;
  grid-gap: 30px;
  gap: 30px;
  padding-left: 10px;
  padding-right: 10px; }
  .AdminSessions .right-side {
    display: flex;
    width: 50%;
    flex-direction: column; }
    .AdminSessions .right-side .custom-accordion {
      height: auto !important; }
      .AdminSessions .right-side .custom-accordion .custom-accordion-title {
        font-family: 'Rubik', 'sans-serif';
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 1.15;
        text-align: left;
        padding: 1.2rem 1rem;
        margin: 0%; }
        .AdminSessions .right-side .custom-accordion .custom-accordion-title:not(.first) {
          border-top: 1px solid rgba(34, 36, 38, 0.15); }
      .AdminSessions .right-side .custom-accordion .custom-accordion-content {
        height: auto !important;
        max-height: 20rem;
        overflow-y: auto;
        border-top: 1px solid rgba(34, 36, 38, 0.15); }
  .AdminSessions .left-side {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem; }
  .AdminSessions .section {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #fff;
    box-shadow: 0.125rem 0.125rem 1.25rem rgba(0, 0, 0, 0.1);
    border-radius: 0.3125rem;
    padding: 0.2rem;
    font-family: 'Rubik', 'sans-serif'; }
    .AdminSessions .section.isOpen {
      -webkit-animation: expandHeight 0.3s ease-in-out;
              animation: expandHeight 0.3s ease-in-out;
      height: 100%;
      overflow-y: hidden; }
    .AdminSessions .section.isClose {
      -webkit-animation: reduceHeight 0.3s ease-in-out;
              animation: reduceHeight 0.3s ease-in-out; }
  .AdminSessions .container {
    opacity: 1;
    transition: opacity 0.5s ease; }
    .AdminSessions .container.isOpen {
      overflow-y: hidden;
      -webkit-animation: fadeIn 0.5s ease-in-out;
              animation: fadeIn 0.5s ease-in-out; }

@-webkit-keyframes expandHeight {
  from {
    height: 100px; }
  to {
    height: 100%; } }

@keyframes expandHeight {
  from {
    height: 100px; }
  to {
    height: 100%; } }

@-webkit-keyframes reduceHeight {
  from {
    height: 100%; }
  to {
    height: 100px; } }

@keyframes reduceHeight {
  from {
    height: 100%; }
  to {
    height: 100px; } }

@media screen and (min-width: 601px) and (max-width: 1199px) {
  .AdminSessions {
    display: block;
    max-width: calc(100vw - 160px);
    padding-left: 0;
    padding-right: 0; }
    .AdminSessions .right-side {
      width: 100%;
      margin-bottom: 3rem; }
    .AdminSessions .left-side {
      width: 100%;
      margin-bottom: 3rem; } }

.parameter-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  padding: 0.6rem 0.4rem;
  overflow-y: hidden; }
  .parameter-container .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center; }
    .parameter-container .header-container .header-parameter {
      display: flex;
      align-items: center;
      text-align: center; }
      .parameter-container .header-container .header-parameter .title {
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 1.15;
        margin: 0%;
        padding: 0.6rem 0rem;
        margin-left: 1rem; }
    .parameter-container .header-container .arrow-button {
      background: none;
      border: none; }
      .parameter-container .header-container .arrow-button .iconArrow {
        transition: -webkit-transform 0.3s ease;
        transition: transform 0.3s ease;
        transition: transform 0.3s ease, -webkit-transform 0.3s ease;
        cursor: pointer; }
        .parameter-container .header-container .arrow-button .iconArrow .rotate180 {
          -webkit-transform: rotate(180deg);
                  transform: rotate(180deg); }

.dropdown-menu {
  width: 18rem;
  position: relative;
  border-radius: 20px 20px 0 0;
  margin: 0.3rem 0; }
  .dropdown-menu.isOpen {
    background-color: #ccc; }
  .dropdown-menu .selected-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 0.3rem;
    border-radius: 20px; }
    .dropdown-menu .selected-box.click {
      cursor: pointer;
      background-color: #D6E0FF; }
    .dropdown-menu .selected-box .iconArrow {
      -webkit-animation: fadeIn 0.5s ease-in;
              animation: fadeIn 0.5s ease-in;
      cursor: pointer;
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      transition: -webkit-transform 0.3s ease;
      transition: transform 0.3s ease;
      transition: transform 0.3s ease, -webkit-transform 0.3s ease; }
      .dropdown-menu .selected-box .iconArrow.rotate180 {
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
        transition: -webkit-transform 0.3s ease;
        transition: transform 0.3s ease;
        transition: transform 0.3s ease, -webkit-transform 0.3s ease; }
    .dropdown-menu .selected-box .selected-option {
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 1.15;
      margin: 0%;
      padding: 0.5rem; }
  .dropdown-menu .options {
    -webkit-animation: fadeIn 0.05s ease-in;
            animation: fadeIn 0.05s ease-in;
    background-color: #ccc;
    border-radius: 0 0 20px 20px;
    position: absolute;
    top: 1.7rem;
    width: 100%;
    left: 0;
    z-index: 1;
    padding: 0.7rem;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    cursor: pointer; }
    .dropdown-menu .options li {
      width: 100%;
      list-style-type: none;
      display: flex;
      align-items: flex-start; }
  .dropdown-menu .selected {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.15;
    margin: 0%; }

.button-add-container {
  border: 2px solid #2764FF;
  border-radius: 0.375rem;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 0.4rem;
  gap: 0.4rem;
  padding: 0.5rem;
  cursor: pointer;
  -webkit-animation: fadeIn 0.5s ease-in;
          animation: fadeIn 0.5s ease-in;
  margin-right: 2rem; }
  .button-add-container .text {
    color: #2764FF;
    font-family: 'Rubik', 'sans-serif';
    font-size: 1.1rem;
    font-weight: 700; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@media screen and (min-width: 601px) and (max-width: 1199px) {
  .header-container .header-parameter {
    padding-left: 0 !important; }
    .header-container .header-parameter .title {
      margin-left: 1.5rem !important; }
  .button-add-container {
    width: 15rem;
    padding: 0.5rem;
    margin-right: 1.5rem; } }

.history-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 0.2rem;
  gap: 0.2rem;
  overflow-y: hidden;
  -webkit-animation: fadeIn 0.5s ease-in;
          animation: fadeIn 0.5s ease-in;
  padding: 0.4rem 3rem; }
  .history-container .explanation-text {
    font-size: small;
    text-align: left;
    margin: 0;
    margin-bottom: 1.5rem;
    text-align: justify; }
  .history-container .param-selector {
    justify-self: start;
    display: flex;
    flex-direction: row;
    grid-gap: 4rem;
    gap: 4rem; }
    .history-container .param-selector .selector {
      font-weight: 700;
      font-size: 1.3rem;
      color: #BBBBBB;
      cursor: pointer;
      margin: 0; }
      .history-container .param-selector .selector.isClicked {
        color: #2764FF; }
  .history-container .filters {
    display: grid;
    align-items: baseline;
    grid-template-areas: "item-one item-one item-one item-one item-one" "item-two item-three item-four item-five item-six";
    grid-template-columns: 0fr 2fr 0fr 2fr 1fr;
    grid-template-rows: 3rem;
    grid-row-gap: 0.5rem; }
    .history-container .filters .input-label {
      padding: 0;
      padding-right: 0.5rem; }
    .history-container .filters .input-box {
      display: flex;
      position: relative;
      height: 75%;
      width: 100%; }
      .history-container .filters .input-box:nth-child(1) {
        flex-grow: 1; }
      .history-container .filters .input-box:nth-child(2) {
        margin-right: 0.5rem; }
      .history-container .filters .input-box .input {
        padding: 0.5rem;
        width: 100%;
        background-color: transparent;
        border: 1px solid #ccc;
        border-radius: 0.3rem 0 0 0.3rem;
        z-index: 10; }
        .history-container .filters .input-box .input.date {
          width: 90%;
          border-radius: 0.3rem;
          padding-left: 2.8rem; }
      .history-container .filters .input-box input[type="date"]::-webkit-calendar-picker-indicator {
        opacity: 0;
        display: block;
        background-repeat: no-repeat;
        width: 25px;
        height: 25px;
        border-width: thin; }
      .history-container .filters .input-box input[type="date"]::-webkit-calendar-picker-indicator {
        position: absolute;
        left: 2%;
        cursor: pointer; }
      .history-container .filters .input-box input::-webkit-datetime-edit-fields-wrapper {
        position: relative; }
      .history-container .filters .input-box input::-webkit-datetime-edit {
        position: relative; }
      .history-container .filters .input-box .search-icon {
        height: 100%;
        width: 2.2rem;
        background-color: #2764FF;
        padding: 0 0.5rem;
        border-radius: 0 0.3rem 0.3rem 0;
        cursor: pointer;
        z-index: 0; }
      .history-container .filters .input-box .reset-icon {
        align-self: center;
        height: 1.8rem;
        width: 1.8rem;
        background-color: #e8e8e8;
        padding: 0.4rem;
        margin-left: 0.3rem;
        border-radius: 100%;
        cursor: pointer;
        z-index: 0; }
      .history-container .filters .input-box .calendar-icon {
        height: 100%;
        width: 2.2rem;
        background-color: #2764FF;
        padding: 0 0.5rem;
        border-radius: 0.3rem 0% 0% 0.3rem;
        cursor: pointer;
        position: absolute;
        z-index: 0; }
  .history-container .item-one {
    grid-area: item-one; }
  .history-container .item-two {
    grid-area: item-two; }
  .history-container .item-three {
    grid-area: item-three; }
  .history-container .item-four {
    grid-area: item-four; }
  .history-container .item-five {
    grid-area: item-five; }
  .history-container .info-text {
    font-size: large;
    color: #BBBBBB; }
  .history-container .history-list {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    grid-gap: 0.6rem;
    gap: 0.6rem; }
    .history-container .history-list .box {
      background-color: #e8e8e8;
      border: solid 2px #e8e8e8;
      width: 100%;
      display: grid;
      grid-template-columns: 5% 40% 30% 25%;
      align-items: center;
      margin: 0;
      padding: 1rem 0.8rem;
      border-radius: 0.2rem;
      font-weight: 400;
      cursor: pointer; }
      .history-container .history-list .box.selected {
        border: solid 2px #2764ff99 !important;
        background-color: #2764ff14; }
    .history-container .history-list .box:hover {
      border: solid 2px #ccc; }
    .history-container .history-list .text-box {
      display: flex;
      flex: row 1;
      align-items: center;
      grid-gap: 0.2rem;
      gap: 0.2rem; }
    .history-container .history-list .default-icon {
      height: 20px;
      align-self: center;
      margin-left: .2em; }
    .history-container .history-list .text {
      font-weight: 400;
      font-size: 1rem;
      color: black;
      white-space: nowrap;
      margin: 0%; }
    .history-container .history-list .data-text {
      font-weight: 600;
      font-size: 1rem;
      color: black;
      margin: 0%;
      text-align: left;
      padding-left: 0.5rem;
      word-break: break-word; }
    .history-container .history-list .date-box {
      display: flex;
      flex-direction: row;
      align-items: center;
      grid-gap: 0.2rem;
      gap: 0.2rem; }
    .history-container .history-list .iconArrow {
      transition: -webkit-transform 0.3s ease;
      transition: transform 0.3s ease;
      transition: transform 0.3s ease, -webkit-transform 0.3s ease;
      cursor: pointer; }
    .history-container .history-list .rotate180 {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@media screen and (min-width: 601px) and (max-width: 1199px) {
  .history-container {
    padding: 1rem 1.5rem; }
    .history-container .filters .input-box .search-icon {
      height: 2.5rem; }
    .history-container .filters .input-box .input {
      width: 100%;
      height: 2.5rem; } }
    @media screen and (min-width: 601px) and (max-width: 1199px) and (min-width: 768px) {
      .history-container .filters .input-box .input {
        width: 100%; } }
    @media screen and (min-width: 601px) and (max-width: 1199px) and (min-width: 992px) {
      .history-container .filters .input-box .input {
        width: 100%; } }

@media screen and (min-width: 601px) and (max-width: 1199px) {
      .history-container .filters .input-box .input.date {
        width: 9.5rem;
        height: 2.5rem; } }
      @media screen and (min-width: 601px) and (max-width: 1199px) and (min-width: 768px) {
        .history-container .filters .input-box .input.date {
          width: 11rem; } }
      @media screen and (min-width: 601px) and (max-width: 1199px) and (min-width: 992px) {
        .history-container .filters .input-box .input.date {
          width: 13.5rem; } }

@media screen and (min-width: 601px) and (max-width: 1199px) {
    .history-container .filters .input-box .calendar-icon {
      height: 2.5rem;
      width: 2.5rem; } }

@media screen and (min-width: 1200px) and (max-width: 1248px) {
  .history-container .history-list .box {
    grid-template-columns: 5% 35% 30% 30%; } }

@media screen and (min-width: 1249px) and (max-width: 1399px) {
  .history-container .history-list .box {
    grid-template-columns: 5% 33% 30% 32%; } }

.AdminModal {
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(51, 51, 51, 0.2);
  justify-content: center;
  align-items: center;
  z-index: 4001;
  overflow-y: hidden; }
  .AdminModal > .content {
    min-height: 35%;
    max-height: 85%;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    background: #fff;
    box-shadow: 0.125rem 0.125rem 1.25rem rgba(0, 0, 0, 0.1);
    border-radius: 0.3125rem;
    padding: 1.5rem 2rem;
    font-family: 'Rubik', 'sans-serif'; }
    .AdminModal > .content.fullSize {
      width: 80vw;
      height: 85%; }
    .AdminModal > .content.smallSize {
      width: 35vw;
      max-height: unset;
      min-height: unset;
      height: auto; }
    .AdminModal > .content .button-row {
      width: 100%;
      display: flex;
      justify-content: end; }
    .AdminModal > .content .button {
      cursor: pointer; }
    .AdminModal > .content .title-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between; }
    .AdminModal > .content .title {
      flex-grow: 3;
      margin: 0%;
      font-weight: 700;
      font-size: 1.5rem;
      text-align: start; }
    .AdminModal > .content .actions {
      flex-grow: 1; }
    .AdminModal > .content .subtitle {
      margin-bottom: 1rem !important;
      color: #A0A0A0 !important;
      font-weight: 400 !important;
      font-size: 1rem !important;
      text-align: start !important; }
    .AdminModal > .content .children {
      height: 100%;
      flex-grow: 1;
      overflow-y: hidden;
      display: flex;
      justify-content: center;
      align-content: center; }
    .AdminModal > .content .footer {
      padding-top: 0.8rem;
      border-top: 1px solid #ccc; }
  .AdminModal .AdminKpis {
    margin: 0; }

@media screen and (min-width: 601px) and (max-width: 1199px) {
  .AdminModal > .content {
    width: 90%;
    height: 60%; }
    .AdminModal > .content.fullSize {
      width: 95vw;
      height: 90vh; }
    .AdminModal > .content.smallSize {
      width: 50vw; }
    .AdminModal > .content .title {
      font-size: 1.25rem;
      margin: 0.5rem;
      margin-left: 0; }
    .AdminModal > .content .subtitle {
      margin-bottom: 1rem !important; } }

.container {
  -webkit-animation: fadeIn 0.5s ease-in-out;
          animation: fadeIn 0.5s ease-in-out;
  display: flex;
  width: 100%;
  flex-direction: column;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  overflow: hidden; }
  .container .info-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 0.2rem;
    gap: 0.2rem; }
    .container .info-container .text {
      font-weight: 700;
      font-size: 1rem;
      color: black;
      margin: 0; }
  .container .header-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between; }
    .container .header-container .phases-container {
      width: 100%;
      display: flex;
      grid-gap: 3rem;
      gap: 3rem; }
      .container .header-container .phases-container .phase {
        font-weight: 600;
        font-size: 1.2rem;
        color: #BBBBBB;
        cursor: pointer; }
        .container .header-container .phases-container .phase.isClicked {
          color: #2764FF; }
    .container .header-container .save-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      grid-gap: 0.8rem;
      gap: 0.8rem;
      border-color: #ccc;
      border-radius: 0.4rem;
      padding: 0.5rem;
      background: none; }
      .container .header-container .save-button .text {
        font-weight: 700;
        font-size: 1rem;
        color: #ccc;
        white-space: nowrap; }
        .container .header-container .save-button .text.isClickable {
          color: #2764FF; }
      .container .header-container .save-button.isClickable {
        cursor: pointer;
        border-color: #2764FF; }
  .container .parameters-container {
    padding: 1.5rem 1rem;
    background-color: #e8e8e8;
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
    overflow-y: unset; }
    .container .parameters-container .parameter-box {
      width: 100%;
      display: grid;
      grid-template-columns: 38% 38% 24%;
      align-items: center;
      margin: 0;
      padding: 0.8rem;
      padding-right: 0.2rem;
      border-radius: 0.375rem;
      font-weight: 400; }
      .container .parameters-container .parameter-box.light {
        background-color: white; }
      .container .parameters-container .parameter-box.dark {
        background-color: #e8e8e8; }
    .container .parameters-container .text {
      font-weight: 400;
      font-size: 1rem;
      color: black;
      margin: 0%;
      justify-self: left;
      word-break: break-word;
      text-align: start; }
    .container .parameters-container .value-box {
      display: flex;
      height: 100%;
      align-items: center;
      position: relative; }
    .container .parameters-container .text-editable {
      width: 3rem;
      border-top-left-radius: 0.2rem;
      border-bottom-left-radius: 0.2rem;
      margin: 0%;
      padding: 4px;
      font-size: 1rem;
      display: flex;
      justify-self: start;
      background-color: #F3F3F3;
      color: #c4c4c4; }
    .container .parameters-container .input {
      background-color: #F3F3F3;
      width: 70%;
      height: 2rem;
      padding: 6px;
      border-top-left-radius: 0.2rem;
      border-bottom-left-radius: 0.2rem;
      text-align: right; }
    .container .parameters-container .input-multi {
      background-color: #F3F3F3;
      width: 70%;
      height: 2rem;
      padding: 6px;
      border-top-left-radius: 0.2rem;
      border-bottom-left-radius: 0.2rem;
      text-align: right;
      margin-left: 4px; }
    .container .parameters-container input::-webkit-outer-spin-button,
    .container .parameters-container input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0; }
    .container .parameters-container .unit {
      background-color: #BBBBBB;
      border-top-right-radius: 0.2rem;
      border-bottom-right-radius: 0.2rem;
      margin: 0%;
      height: 2rem;
      width: 15%;
      display: flex;
      align-items: center;
      justify-content: center; }
    .container .parameters-container .warning-box {
      padding-left: 0.5rem;
      width: 15%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0%; }
    .container .parameters-container .warning-button {
      width: 1rem;
      height: 1rem; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@media screen and (min-width: 601px) and (max-width: 1199px) {
  .container .parameters-container .parameter-box {
    width: 100%;
    display: grid;
    grid-template-columns: 35% 40% 25%;
    align-items: center;
    margin: 0;
    padding: 0.8rem;
    padding-right: 0.2rem;
    border-radius: 0.375rem;
    font-weight: 400; }
  .container .parameters-container .text {
    white-space: break-spaces;
    width: 14rem;
    word-break: break-word;
    text-align: start; }
  .container .parameters-container .input {
    height: 2rem; }
  .container .parameters-container .input-multi {
    height: 2rem; } }

.discard-text {
  margin: 0%;
  font-weight: 900;
  font-size: 1rem;
  color: #2764FF;
  cursor: pointer; }

.modal-param {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  grid-gap: 1rem;
  gap: 1rem; }
  .modal-param .explanation-text {
    font-size: small;
    text-align: left;
    margin: 0;
    margin-bottom: 1.5rem;
    text-align: justify; }
  .modal-param .modal-param-body {
    overflow-y: auto;
    padding-right: 1rem; }
  .modal-param .info-accordion {
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    padding: 1rem 1.2rem;
    margin-bottom: 1rem; }
    .modal-param .info-accordion .info-icon-accordion {
      color: #2764FF;
      padding-right: 0.2rem; }
  .modal-param .options-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 1rem; }
  .modal-param .section-selector {
    justify-self: start;
    display: flex;
    flex-direction: row;
    grid-gap: 4rem;
    gap: 4rem; }
    .modal-param .section-selector .selector {
      font-weight: 700;
      font-size: 1.6rem;
      color: #BBBBBB;
      cursor: pointer;
      margin: 0; }
      .modal-param .section-selector .selector.isClicked {
        color: #2764FF; }
  .modal-param .options {
    justify-self: end;
    align-self: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem; }
    .modal-param .options .option {
      display: flex;
      flex-direction: row;
      align-items: center;
      grid-gap: 0.5rem;
      gap: 0.5rem; }
  .modal-param .modal-param-content {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    width: 100%;
    flex: 1 1;
    grid-gap: 1.5rem;
    gap: 1.5rem; }
    .modal-param .modal-param-content .container {
      height: 100%; }
      .modal-param .modal-param-content .container .filters {
        display: flex;
        margin: 1rem 0 1rem 0; }
        .modal-param .modal-param-content .container .filters .input-box {
          display: flex;
          align-items: center;
          position: relative;
          height: 75%;
          width: 100%; }
          .modal-param .modal-param-content .container .filters .input-box .input {
            padding: 0.5rem;
            height: 2.5rem;
            width: 100%;
            background-color: #F0F0F0;
            border: 1px solid #ccc;
            border-radius: 0.3rem 0 0 0.3rem; }
          .modal-param .modal-param-content .container .filters .input-box .search-icon {
            height: 2.5rem;
            width: 2.5rem;
            background-color: #2764FF;
            padding: 0 0.5rem;
            border-radius: 0 0.3rem 0.3rem 0;
            cursor: pointer;
            z-index: 0;
            margin-right: 3rem; }

.footer-section {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.8rem;
  border-top: 1px solid #ccc; }

.warning {
  background-color: #fff6d4;
  border: 2px solid #D8A930;
  border-radius: 0.375rem;
  display: flex;
  grid-gap: 0.8rem;
  gap: 0.8rem;
  padding: 0.4rem 2rem;
  cursor: default; }
  .warning .text {
    font-size: 1rem;
    font-weight: 700;
    color: #D8A930; }

.button-edit {
  width: 14rem;
  border: 2px solid #2764FF;
  border-radius: 0.375rem;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 0.4rem;
  gap: 0.4rem;
  cursor: pointer; }
  .button-edit .text {
    color: #2764FF;
    font-family: 'Rubik', 'sans-serif';
    font-size: 1.1rem;
    font-weight: 700; }

.buttons-save-mode {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 2rem;
  gap: 2rem; }
  .buttons-save-mode .discard-text {
    margin: 0%;
    font-weight: 900;
    font-size: 1rem;
    color: #2764FF;
    cursor: pointer; }
  .buttons-save-mode .confirm-button-modal, .buttons-save-mode .delete-button-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    cursor: pointer;
    padding: 0.2rem 1.5rem;
    background-color: #2764FF;
    border-radius: 0.375rem;
    border: 2px solid #2764FF; }
    .buttons-save-mode .confirm-button-modal.disabled, .buttons-save-mode .disabled.delete-button-modal {
      background-color: #ccc;
      cursor: auto;
      border: 2px solid #BBBBBB; }
    .buttons-save-mode .confirm-button-modal .confirm-text, .buttons-save-mode .delete-button-modal .confirm-text {
      font-family: 'Rubik', 'sans-serif';
      text-wrap: nowrap;
      font-size: 1.1rem;
      font-weight: 700;
      margin: 0%;
      color: white; }
  .buttons-save-mode .delete-button-modal {
    background-color: white;
    border: 2px solid red; }
    .buttons-save-mode .delete-button-modal .delete-text {
      font-family: 'Rubik', 'sans-serif';
      text-wrap: nowrap;
      font-size: 1.1rem;
      font-weight: 700;
      margin: 0%;
      color: red; }

.save-modal {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  overflow-x: hidden;
  align-self: end; }
  .save-modal .save-option {
    width: 100%;
    display: flex;
    width: 20rem;
    justify-self: center;
    justify-items: left;
    grid-gap: 1.5rem;
    gap: 1.5rem; }
  .save-modal .input {
    background-color: #e8e8e8;
    border: 1px solid #BBBBBB;
    border-radius: 4px;
    padding: 0.7rem 0.5rem;
    width: 20rem;
    color: #333; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.load {
  background: #fff;
  color: #2764FF; }
  .load svg {
    -webkit-animation: 2s linear infinite svg-animation;
    animation: 2s linear infinite svg-animation;
    max-width: 25px;
    margin-top: .5rem; }

@-webkit-keyframes svg-animation {
  0% {
    -webkit-transform: rotateZ(0deg);
    transform: rotateZ(0deg); }
  100% {
    -webkit-transform: rotateZ(360deg);
    transform: rotateZ(360deg); } }

@keyframes svg-animation {
  0% {
    -webkit-transform: rotateZ(0deg);
    transform: rotateZ(0deg); }
  100% {
    -webkit-transform: rotateZ(360deg);
    transform: rotateZ(360deg); } }
    .load svg circle {
      -webkit-animation: 1.4s ease-in-out infinite both circle-animation;
              animation: 1.4s ease-in-out infinite both circle-animation;
      display: block;
      fill: transparent;
      stroke: #2764FF;
      stroke-linecap: round;
      stroke-dasharray: 283;
      stroke-dashoffset: 280;
      stroke-width: 10px;
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%; }

@-webkit-keyframes circle-animation {
  0%,
  25% {
    stroke-dashoffset: 280;
    -webkit-transform: rotate(0);
    transform: rotate(0); }
  50%,
  75% {
    stroke-dashoffset: 75;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg); }
  100% {
    stroke-dashoffset: 280;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes circle-animation {
  0%,
  25% {
    stroke-dashoffset: 280;
    -webkit-transform: rotate(0);
    transform: rotate(0); }
  50%,
  75% {
    stroke-dashoffset: 75;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg); }
  100% {
    stroke-dashoffset: 280;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@media screen and (min-width: 601px) and (max-width: 767px) {
  .modal-param .info-accordion {
    padding: 0 1.2rem;
    margin-bottom: 0; }
  .modal-param .options-row {
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
    align-items: flex-start; }
  .modal-param .options {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: flex-start;
    grid-gap: 3rem;
    gap: 3rem;
    margin-bottom: 1.5rem; }
  .modal-param-content .container .header-container {
    margin-top: 0.5rem; } }

@media screen and (min-width: 768px) and (max-width: 1199px) {
  .modal-param .info-accordion {
    padding: 0 1.2rem;
    margin-bottom: 0; } }

.media-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  overflow-x: hidden; }
  .media-container .header-media {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .media-container .lang-selector {
    display: flex;
    grid-gap: 0.2rem;
    gap: 0.2rem;
    -webkit-animation: fadeIn 0.5s ease-in-out;
            animation: fadeIn 0.5s ease-in-out; }
    .media-container .lang-selector .lang {
      background-color: #d9d9d9;
      margin: 0%;
      width: 2.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      cursor: pointer; }
      .media-container .lang-selector .lang.selected {
        background-color: #2764FF;
        color: white; }
      .media-container .lang-selector .lang.first {
        border-radius: 5px 0 0 5px; }
      .media-container .lang-selector .lang.second {
        border-radius: 0 5px 5px 0; }
  .media-container .phases-container {
    width: 100%;
    display: flex;
    grid-gap: 2rem;
    gap: 2rem; }
    .media-container .phases-container .phase {
      font-weight: 600;
      font-size: 1.2rem;
      color: #BBBBBB;
      cursor: pointer; }
      .media-container .phases-container .phase.isClicked {
        color: #2764FF; }
  .media-container .title-media {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.15;
    padding: 01rem 0rem; }
  .media-container .content-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
    -webkit-animation: fadeIn 0.5s ease-in-out;
            animation: fadeIn 0.5s ease-in-out;
    overflow: hidden; }
    .media-container .content-container .header-content {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .media-container .content-container .header-content .media-selector {
        display: flex;
        flex-direction: row;
        align-items: center;
        grid-gap: 4rem;
        gap: 4rem; }
        .media-container .content-container .header-content .media-selector .text {
          font-weight: 700;
          font-size: 1.4rem;
          color: #BBBBBB;
          cursor: pointer;
          margin: 0; }
          .media-container .content-container .header-content .media-selector .text.isClicked {
            color: #2764FF; }
    .media-container .content-container .videos-container {
      flex: 1 1;
      display: flex;
      flex-direction: column;
      grid-gap: 1rem;
      gap: 1rem;
      padding-top: 1rem; }
    .media-container .content-container .filters {
      display: flex;
      justify-content: space-between;
      margin-top: 2rem;
      width: 100%; }
      .media-container .content-container .filters .input-box {
        display: flex;
        align-items: center;
        position: relative;
        width: 100%; }
        .media-container .content-container .filters .input-box .input {
          padding: 0.5rem;
          width: 100%;
          height: 2.5rem;
          background-color: #F0F0F0;
          border: 1px solid #ccc;
          border-radius: 0.3rem 0% 0% 0.3rem; }
          .media-container .content-container .filters .input-box .input.date {
            width: 10rem;
            border-radius: 0 0.3rem 0.3rem 0; }
        .media-container .content-container .filters .input-box input[type="date"]::-webkit-calendar-picker-indicator {
          opacity: 0;
          display: block;
          background-repeat: no-repeat;
          width: 25px;
          height: 25px;
          border-width: thin; }
        .media-container .content-container .filters .input-box input[type="date"]::-webkit-calendar-picker-indicator {
          position: absolute;
          left: 2%;
          cursor: pointer; }
        .media-container .content-container .filters .input-box input::-webkit-datetime-edit-fields-wrapper {
          position: relative;
          left: 25%; }
        .media-container .content-container .filters .input-box input::-webkit-datetime-edit {
          position: relative; }
        .media-container .content-container .filters .input-box .search-icon {
          height: 100%;
          width: 2.5rem;
          height: 2.5rem;
          background-color: #2764FF;
          padding: 0 0.5rem;
          border-radius: 0 0.3rem 0.3rem 0;
          cursor: pointer;
          margin-right: 3rem; }
        .media-container .content-container .filters .input-box .calendar-icon {
          height: 100%;
          width: 2.2rem;
          background-color: #2764FF;
          padding: 0 0.5rem;
          border-radius: 0.3rem 0% 0% 0.3rem;
          cursor: pointer;
          position: absolute;
          z-index: 0; }
    .media-container .content-container .chapters-container {
      padding-top: 0.4rem;
      flex: 1 1;
      display: flex;
      flex-direction: column;
      grid-gap: 1rem;
      gap: 1rem;
      overflow-y: visible;
      width: 100%; }
    .media-container .content-container .media {
      display: flex;
      flex-direction: column;
      grid-gap: 0.7rem;
      gap: 0.7rem;
      padding-top: 0.5rem; }
      .media-container .content-container .media .header-documents {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-right: 0.5rem; }
      .media-container .content-container .media .edit-button {
        cursor: pointer;
        width: 1rem;
        height: 1rem; }
      .media-container .content-container .media .warning-button {
        width: 1.2rem;
        height: 1.2rem; }
      .media-container .content-container .media .chapter-title-box {
        display: flex;
        align-items: center;
        grid-gap: 0.4rem;
        gap: 0.4rem; }
        .media-container .content-container .media .chapter-title-box .chapter-title {
          margin: 0;
          font-size: 1.2rem;
          font-weight: 700;
          color: #787878;
          text-align: start; }
      .media-container .content-container .media .video-box {
        background-color: #e8e8e8;
        height: 5rem;
        padding: 1rem;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        grid-gap: 0.2rem;
        gap: 0.2rem;
        border-radius: 0.375rem; }
        .media-container .content-container .media .video-box .edit-icon {
          cursor: pointer; }
        .media-container .content-container .media .video-box .video-text-container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          grid-gap: 0.2rem;
          gap: 0.2rem; }
          .media-container .content-container .media .video-box .video-text-container .video-title {
            font-weight: 600;
            margin: 0;
            font-size: 1rem; }
          .media-container .content-container .media .video-box .video-text-container .video-url {
            margin: 0;
            cursor: pointer;
            font-size: 1.1rem;
            font-weight: 600;
            font-style: italic;
            transition: -webkit-text-decoration 0.2s;
            transition: text-decoration 0.2s;
            transition: text-decoration 0.2s, -webkit-text-decoration 0.2s; }
          .media-container .content-container .media .video-box .video-text-container .video-url:hover {
            text-decoration: underline; }
      .media-container .content-container .media .element-container {
        background-color: #D9D9D9;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        padding: 0.75rem;
        border-radius: 0.375rem;
        -webkit-animation: fadeIn 0.2s ease-in-out;
                animation: fadeIn 0.2s ease-in-out; }
        .media-container .content-container .media .element-container .element-title {
          margin: 0%;
          font-weight: 500;
          font-size: 1.1rem; }
      .media-container .content-container .media .media-box {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        grid-gap: 0.5rem;
        gap: 0.5rem;
        height: 5rem;
        width: 100%;
        background-color: #e8e8e8;
        border-radius: 0.375rem;
        cursor: pointer; }
        .media-container .content-container .media .media-box .upload-text {
          font-weight: 600;
          color: #8b8b8b; }
      .media-container .content-container .media .documents-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        grid-gap: 1rem;
        gap: 1rem; }
      .media-container .content-container .media .document-box {
        background-color: #e8e8e8;
        position: relative;
        display: flex;
        height: 5rem;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        grid-gap: 0.4rem;
        gap: 0.4rem;
        padding: 0.6rem;
        border-radius: 0.5rem; }
        .media-container .content-container .media .document-box .text-container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          grid-gap: 0.2rem;
          gap: 0.2rem; }
          .media-container .content-container .media .document-box .text-container .title-docu {
            font-weight: 600;
            margin: 0;
            font-size: 1rem; }
          .media-container .content-container .media .document-box .text-container .download-link {
            cursor: pointer;
            color: #2764FF;
            text-align: left; }
          .media-container .content-container .media .document-box .text-container .info-docu {
            margin: 0;
            font-style: italic; }
        .media-container .content-container .media .document-box .format {
          position: absolute;
          bottom: 0%;
          right: 0%;
          color: white;
          font-size: 0.7rem;
          font-weight: 700;
          padding: 0 0.2rem; }
          .media-container .content-container .media .document-box .format.pdf {
            background-color: red; }
          .media-container .content-container .media .document-box .format.word {
            background-color: #2764FF; }
      .media-container .content-container .media .icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        grid-gap: 1rem;
        gap: 1rem; }
        .media-container .content-container .media .icon-container .icon {
          display: flex;
          align-items: flex-start;
          justify-self: flex-start;
          cursor: pointer; }

.upload-documents {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  grid-gap: 0rem;
  gap: 0rem;
  padding-top: 0;
  overflow-y: auto;
  -webkit-animation: fadeIn 0.5s ease-in;
          animation: fadeIn 0.5s ease-in; }
  .upload-documents .upload-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem; }
  .upload-documents .input-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    grid-gap: 0.5rem;
    gap: 0.5rem; }
    .upload-documents .input-container.video {
      padding-bottom: 6rem; }
  .upload-documents .label-container {
    display: flex;
    grid-gap: 2rem;
    gap: 2rem; }
  .upload-documents .input-label {
    text-align: start;
    padding-left: 0.2rem;
    color: #BBBBBB;
    font-weight: 500;
    font-size: 0.9rem;
    font-weight: 700;
    margin: 0%; }
  .upload-documents .input {
    background-color: #e8e8e8;
    border: 1px solid #BBBBBB;
    border-radius: 4px;
    padding: 0.6rem;
    width: 28rem;
    color: #333;
    font-size: 1rem;
    font-weight: 600; }
    .upload-documents .input.video {
      width: 35rem;
      font-size: 1.2rem;
      font-weight: 600; }
  .upload-documents .upload-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-content: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
    grid-gap: 1.5rem;
    gap: 1.5rem; }
  .upload-documents .upload-subtitle {
    margin: 0;
    font-size: 1.2rem;
    font-weight: 700;
    color: #BBBBBB; }
  .upload-documents .new-set-documents {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 2rem;
    -webkit-column-gap: 2rem;
            column-gap: 2rem;
    grid-row-gap: 0.8rem;
    row-gap: 0.8rem; }
  .upload-documents .file-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem; }
  .upload-documents .file {
    height: 16rem;
    width: 18rem;
    position: relative;
    background-color: #e8e8e8;
    border-radius: 0.5rem;
    border: 2px solid #BBBBBB;
    display: flex;
    flex-direction: column;
    grid-gap: 0.6rem;
    gap: 0.6rem;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1); }
    .upload-documents .file.empty {
      border: 1px dashed #6e6e6e;
      background-color: #ccc;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer; }
    .upload-documents .file .text-empty {
      font-weight: 600;
      margin: 0%;
      color: #6e6e6e;
      cursor: pointer; }
    .upload-documents .file .file-header {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      grid-gap: 0.2rem;
      gap: 0.2rem; }
      .upload-documents .file .file-header .file-title {
        margin: 0%;
        font-weight: 700;
        font-size: 1rem;
        color: #333; }
      .upload-documents .file .file-header .file-text {
        margin: 0%;
        font-weight: 400;
        font-size: 1rem;
        color: #333; }
        .upload-documents .file .file-header .file-text.type {
          font-style: italic; }
    .upload-documents .file .file-footer {
      width: 100%;
      display: flex;
      grid-gap: 1rem;
      gap: 1rem;
      justify-content: flex-end;
      padding: 0.5rem; }
      .upload-documents .file .file-footer .icon {
        height: 1.2rem;
        width: 1.2rem;
        cursor: pointer; }
  .upload-documents .error-container {
    display: flex;
    align-items: center;
    grid-gap: 0.4rem;
    gap: 0.4rem;
    height: 1.2rem; }
    .upload-documents .error-container .error-text {
      margin: 0%;
      font-size: 1rem;
      font-weight: 400; }
  .upload-documents .upload {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    cursor: pointer; }
    .upload-documents .upload .upload-text {
      margin: 0%;
      font-weight: 900;
      font-size: 1.2rem;
      color: #2764FF; }
  .upload-documents .button-add-container {
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 0.4rem;
    gap: 0.4rem;
    padding: 0.5rem;
    cursor: pointer;
    -webkit-animation: fadeIn 0.5s ease-in;
            animation: fadeIn 0.5s ease-in;
    margin-right: 2rem; }
    .upload-documents .button-add-container .text {
      color: #2764FF;
      font-family: 'Rubik', 'sans-serif';
      font-size: 1rem;
      font-weight: 700; }

.footer-upload {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between; }

.warning {
  background-color: #fff6d4;
  border: 2px solid #D8A930;
  border-radius: 0.375rem;
  display: flex;
  grid-gap: 0.8rem;
  gap: 0.8rem;
  padding: 0.4rem 2rem; }
  .warning .text {
    font-size: 1rem;
    font-weight: 700;
    color: #D8A930; }

.button-confirm-container {
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 2rem;
  gap: 2rem; }
  .button-confirm-container .discard-text {
    margin: 0%;
    font-weight: 900;
    font-size: 1rem;
    color: #2764FF;
    cursor: pointer; }
  .button-confirm-container .confirm-button {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    padding: 0.2rem 1.5rem;
    cursor: pointer;
    background-color: #2764FF;
    border-radius: 0.375rem;
    border: 2px solid #2764FF;
    color: white; }
    .button-confirm-container .confirm-button.disabled {
      background-color: #ccc;
      cursor: auto;
      border: 2px solid #BBBBBB; }
    .button-confirm-container .confirm-button .confirm-text {
      font-family: 'Rubik', 'sans-serif';
      text-wrap: nowrap;
      font-size: 1.1rem;
      font-weight: 700;
      margin: 0%;
      color: white; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@media screen and (min-width: 601px) and (max-width: 1199px) {
  .upload-documents .upload-header {
    width: 100%;
    display: block;
    margin-bottom: 1rem; }
    .upload-documents .upload-header .input-container {
      margin-bottom: 1rem; }
      .upload-documents .upload-header .input-container .input {
        width: 100%; } }

.Tooltip {
  min-width: 100px;
  padding: 0.4rem; }
  .Tooltip .content {
    position: relative; }
    .Tooltip .content .close-button {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      width: 10px;
      height: 10px;
      padding: 0; }
      .Tooltip .content .close-button img {
        margin: 0;
        display: block; }

.image-tooltip-container {
  display: inline-block;
  position: relative; }

.tooltip {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  z-index: 1;
  white-space: nowrap; }

.tooltip-top {
  bottom: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%); }

.tooltip-bottom {
  top: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%); }

.tooltip-left {
  top: 50%;
  right: 100%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.tooltip-right {
  top: 50%;
  left: 100%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.tooltip-bottom-left {
  top: 100%;
  right: 0; }

.selector {
  display: flex;
  grid-gap: 0.2rem;
  gap: 0.2rem;
  padding: 0.2rem 0 0.2rem 0;
  -webkit-animation: fadeIn 0.5s ease-in-out;
          animation: fadeIn 0.5s ease-in-out; }
  .selector .option {
    background-color: #d9d9d9;
    margin: 0%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    cursor: pointer;
    padding: 0.5rem 1.2rem !important; }
    .selector .option.selected {
      background-color: #2764FF;
      color: white; }
    .selector .option.first {
      border-radius: 5px 0 0 5px; }
    .selector .option.last {
      border-radius: 0 5px 5px 0; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.load {
  background: #fff;
  color: #2764FF; }
  .load svg {
    -webkit-animation: 2s linear infinite svg-animation;
    animation: 2s linear infinite svg-animation;
    max-width: 25px;
    margin-top: .5rem; }

@-webkit-keyframes svg-animation {
  0% {
    -webkit-transform: rotateZ(0deg);
    transform: rotateZ(0deg); }
  100% {
    -webkit-transform: rotateZ(360deg);
    transform: rotateZ(360deg); } }

@keyframes svg-animation {
  0% {
    -webkit-transform: rotateZ(0deg);
    transform: rotateZ(0deg); }
  100% {
    -webkit-transform: rotateZ(360deg);
    transform: rotateZ(360deg); } }
    .load svg circle {
      -webkit-animation: 1.4s ease-in-out infinite both circle-animation;
              animation: 1.4s ease-in-out infinite both circle-animation;
      display: block;
      fill: transparent;
      stroke: #2764FF;
      stroke-linecap: round;
      stroke-dasharray: 283;
      stroke-dashoffset: 280;
      stroke-width: 10px;
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%; }

@-webkit-keyframes circle-animation {
  0%,
  25% {
    stroke-dashoffset: 280;
    -webkit-transform: rotate(0);
    transform: rotate(0); }
  50%,
  75% {
    stroke-dashoffset: 75;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg); }
  100% {
    stroke-dashoffset: 280;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes circle-animation {
  0%,
  25% {
    stroke-dashoffset: 280;
    -webkit-transform: rotate(0);
    transform: rotate(0); }
  50%,
  75% {
    stroke-dashoffset: 75;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg); }
  100% {
    stroke-dashoffset: 280;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.buttons-save-mode {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 2rem;
  gap: 2rem; }
  .buttons-save-mode .discard-text {
    margin: 0%;
    font-weight: 900;
    font-size: 1rem;
    color: #2764FF;
    cursor: pointer; }
  .buttons-save-mode .confirm-button, .buttons-save-mode .delete-button {
    width: 14rem;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    cursor: pointer;
    background-color: #2764FF;
    border-radius: 0.375rem;
    border: 2px solid #2764FF; }
    .buttons-save-mode .confirm-button .confirm-text, .buttons-save-mode .delete-button .confirm-text, .buttons-save-mode .delete-button .delete-text {
      font-family: 'Rubik', 'sans-serif';
      font-size: 1.1rem;
      font-weight: 700;
      margin: 0%;
      color: white; }
  .buttons-save-mode .delete-button {
    background-color: white;
    border: 2px solid red; }
    .buttons-save-mode .delete-button .delete-text {
      color: red; }

.save-modal {
  display: flex;
  width: 100%;
  height: 100% !important;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: hidden;
  grid-gap: 2rem;
  gap: 2rem; }
  .save-modal .save-options {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-self: center;
    justify-items: left;
    grid-gap: 1.5rem;
    gap: 1.5rem; }
    .save-modal .save-options .save-option {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .save-modal .save-options .save-option .selector .option {
        width: 8rem; }
  .save-modal .input {
    background-color: #e8e8e8;
    border: 1px solid #BBBBBB;
    border-radius: 4px;
    padding: 0.7rem 0.5rem;
    width: 100%;
    color: #333; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@media screen and (min-width: 601px) and (max-width: 1199px) {
  .buttons-save-mode .confirm-button, .buttons-save-mode .delete-button {
    width: 16rem; } }

.modal {
  height: auto;
  top: auto;
  left: auto;
  bottom: auto;
  right: auto;
  display: flex; }

.loading-spinner {
  background: #fff;
  color: #2764FF;
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-items: center;
  align-self: center; }
  .loading-spinner .spinner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .loading-spinner .spinner-container .spinner {
      width: 2rem; }
  .loading-spinner svg {
    -webkit-animation: 2s linear infinite svg-animation;
    animation: 2s linear infinite svg-animation;
    max-width: 25px;
    margin-top: .5rem; }

@-webkit-keyframes svg-animation {
  0% {
    -webkit-transform: rotateZ(0deg);
    transform: rotateZ(0deg); }
  100% {
    -webkit-transform: rotateZ(360deg);
    transform: rotateZ(360deg); } }

@keyframes svg-animation {
  0% {
    -webkit-transform: rotateZ(0deg);
    transform: rotateZ(0deg); }
  100% {
    -webkit-transform: rotateZ(360deg);
    transform: rotateZ(360deg); } }
    .loading-spinner svg circle {
      -webkit-animation: 1.4s ease-in-out infinite both circle-animation;
              animation: 1.4s ease-in-out infinite both circle-animation;
      display: block;
      fill: transparent;
      stroke: #2764FF;
      stroke-linecap: round;
      stroke-dasharray: 283;
      stroke-dashoffset: 280;
      stroke-width: 10px;
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%; }

@-webkit-keyframes circle-animation {
  0%,
  25% {
    stroke-dashoffset: 280;
    -webkit-transform: rotate(0);
    transform: rotate(0); }
  50%,
  75% {
    stroke-dashoffset: 75;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg); }
  100% {
    stroke-dashoffset: 280;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes circle-animation {
  0%,
  25% {
    stroke-dashoffset: 280;
    -webkit-transform: rotate(0);
    transform: rotate(0); }
  50%,
  75% {
    stroke-dashoffset: 75;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg); }
  100% {
    stroke-dashoffset: 280;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.filters.session {
  padding: 1rem;
  display: grid;
  align-items: baseline;
  grid-template-areas: "item-one item-one item-one item-one item-one" "item-two item-three item-four item-five item-six";
  grid-template-columns: 0fr 2fr 0fr 2fr 1fr;
  grid-template-rows: 3rem;
  grid-row-gap: 0.5rem; }
  .filters.session .item-one {
    grid-area: item-one; }
  .filters.session .item-two {
    grid-area: item-two; }
  .filters.session .item-three {
    grid-area: item-three; }
  .filters.session .item-four {
    grid-area: item-four; }
  .filters.session .item-five {
    grid-area: item-five; }
  .filters.session .input-label {
    padding: 0;
    padding-right: 0.5rem; }
  .filters.session .input-box {
    display: flex;
    position: relative;
    height: 75%;
    width: 100%; }
    .filters.session .input-box:nth-child(1) {
      flex-grow: 1; }
    .filters.session .input-box:nth-child(2) {
      margin-right: 0.5rem; }
    .filters.session .input-box .input {
      padding: 0.5rem;
      width: 100%;
      background-color: transparent;
      border: 1px solid #ccc;
      border-radius: 0.3rem 0 0 0.3rem;
      z-index: 10; }
      .filters.session .input-box .input.date {
        width: 90%;
        border-radius: 0.3rem;
        padding-left: 2.8rem; }
    .filters.session .input-box input[type="date"]::-webkit-calendar-picker-indicator {
      opacity: 0;
      display: block;
      background-repeat: no-repeat;
      width: 25px;
      height: 25px;
      border-width: thin; }
    .filters.session .input-box input[type="date"]::-webkit-calendar-picker-indicator {
      position: absolute;
      left: 2%;
      cursor: pointer; }
    .filters.session .input-box input::-webkit-datetime-edit-fields-wrapper {
      position: relative; }
    .filters.session .input-box input::-webkit-datetime-edit {
      position: relative; }
    .filters.session .input-box .search-icon {
      height: 100%;
      width: 2.2rem;
      background-color: #2764FF;
      padding: 0 0.5rem;
      border-radius: 0 0.3rem 0.3rem 0;
      cursor: pointer;
      z-index: 0; }
    .filters.session .input-box .reset-icon {
      align-self: center;
      height: 1.8rem;
      width: 1.8rem;
      background-color: #e8e8e8;
      padding: 0.4rem;
      margin-left: 0.3rem;
      border-radius: 100%;
      cursor: pointer;
      z-index: 0; }
    .filters.session .input-box .calendar-icon {
      height: 100%;
      width: 2.2rem;
      background-color: #2764FF;
      padding: 0 0.5rem;
      border-radius: 0.3rem 0% 0% 0.3rem;
      cursor: pointer;
      position: absolute;
      z-index: 0; }

.session-section {
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
  grid-gap: 1rem;
  gap: 1rem; }
  .session-section .param-selector {
    justify-self: start;
    display: flex;
    flex-direction: row;
    grid-gap: 4rem;
    gap: 4rem; }
    .session-section .param-selector .selector {
      font-weight: 700;
      font-size: 1.3rem;
      color: #BBBBBB;
      cursor: pointer;
      margin: 0; }
      .session-section .param-selector .selector.isClicked {
        color: #2764FF; }
  .session-section .header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }
    .session-section .header-container .title {
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 2.6rem;
      margin: 0%; }
      .session-section .header-container .title .old-session {
        font-size: 1rem;
        color: #fff;
        padding: 0.5rem 1rem;
        border-radius: 1rem;
        white-space: nowrap;
        background-color: #d8a930;
        margin-left: 1rem; }
    .session-section .header-container .view-session-parameter {
      border: 2px solid #2764FF;
      border-radius: 0.375rem;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      grid-gap: 0.4rem;
      grid-gap: 0.4rem;
      gap: 0.4rem;
      padding: 0.5rem 1rem;
      cursor: pointer; }
      .session-section .header-container .view-session-parameter .text {
        color: #2764FF;
        font-family: 'Rubik', 'sans-serif';
        font-size: 1.1rem;
        font-weight: 600;
        padding: 0.1rem 0rem; }
      .session-section .header-container .view-session-parameter .eye-icon {
        color: #2764FF; }
    .session-section .header-container .archive-session-button {
      border: 2px solid #E94040;
      border-radius: 0.375rem;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      grid-gap: 0.4rem;
      grid-gap: 0.4rem;
      gap: 0.4rem;
      padding: 0.5rem 1rem;
      cursor: pointer;
      margin-right: 1rem; }
      .session-section .header-container .archive-session-button .text {
        color: #E94040;
        font-family: 'Rubik', 'sans-serif';
        font-size: 1.1rem;
        font-weight: 600;
        padding: 0.1rem 0rem; }
      .session-section .header-container .archive-session-button .archive-session-icon {
        color: #E94040; }
    .session-section .header-container .stop-session {
      border: 2px solid #E94040;
      border-radius: 0.375rem;
      background-color: #E94040;
      display: flex;
      justify-content: center;
      align-items: center;
      grid-gap: 0.6rem;
      gap: 0.6rem;
      cursor: pointer;
      padding: 0.5rem 1rem;
      margin-right: 1rem; }
      .session-section .header-container .stop-session .text {
        color: #fff;
        font-family: 'Rubik', 'sans-serif';
        font-size: 1.1rem;
        font-weight: 600;
        padding: 0.1rem 0rem; }
      .session-section .header-container .stop-session .stop-session-icon {
        color: #fff; }
    .session-section .header-container .edit-session {
      border: 2px solid #2764FF;
      border-radius: 0.375rem;
      background-color: #2764FF;
      display: flex;
      justify-content: center;
      align-items: center;
      grid-gap: 0.6rem;
      gap: 0.6rem;
      cursor: pointer;
      padding: 0.5rem 1rem; }
      .session-section .header-container .edit-session .text {
        color: #fff;
        font-family: 'Rubik', 'sans-serif';
        font-size: 1.1rem;
        font-weight: 600;
        padding: 0.1rem 0rem; }
  .session-section .content-container {
    max-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
    .session-section .content-container .subtitle {
      font-weight: 700;
      font-size: 1.5rem;
      color: #2764FF;
      margin: 0;
      margin-bottom: 1.4rem; }
    .session-section .content-container .current-session {
      flex-basis: 30%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-evenly; }
      .session-section .content-container .current-session .warning.parameter-na {
        width: 100%;
        margin-bottom: 1.4rem; }
      .session-section .content-container .current-session .session-info {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start; }
        .session-section .content-container .current-session .session-info .info-box {
          flex-basis: 50%;
          flex-grow: 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          grid-gap: 1.5rem;
          gap: 1.5rem;
          margin-bottom: 1.3rem; }
          .session-section .content-container .current-session .session-info .info-box.session-id {
            flex-basis: 100%; }
          .session-section .content-container .current-session .session-info .info-box.date {
            flex-basis: 100%; }
          .session-section .content-container .current-session .session-info .info-box.parameter {
            flex-basis: 100%; }
          .session-section .content-container .current-session .session-info .info-box.teachers {
            flex-basis: 100%; }
        .session-section .content-container .current-session .session-info .text-info {
          margin: 0%;
          font-weight: 400;
          font-size: 1.2rem;
          text-align: left;
          width: 10rem; }
        .session-section .content-container .current-session .session-info .text-data {
          margin: 0%;
          font-weight: 500;
          font-size: 1.2rem;
          text-align: end; }
          .session-section .content-container .current-session .session-info .text-data.pending, .session-section .content-container .current-session .session-info .text-data.selected {
            color: #fff;
            padding: 0.2rem 1rem;
            border-radius: 1rem;
            white-space: nowrap; }
          .session-section .content-container .current-session .session-info .text-data.pending {
            background-color: #d8a930; }
          .session-section .content-container .current-session .session-info .text-data.selected {
            background-color: #28a467; }
      .session-section .content-container .current-session .no-session {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .session-section .content-container .current-session .no-session .info-title {
          font-weight: 700;
          font-size: 1.4rem;
          margin: 0; }
        .session-section .content-container .current-session .no-session .info-text {
          font-weight: 500;
          font-size: 1.1rem;
          margin: 0;
          color: #a6a6a6; }
    .session-section .content-container .history-sessions {
      flex-basis: auto;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start; }
      .session-section .content-container .history-sessions .list {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-bottom: 1rem; }
        .session-section .content-container .history-sessions .list .header-list {
          display: flex;
          flex-direction: row;
          padding: 0.3rem 1.5rem 0.3rem 0.8rem; }
        .session-section .content-container .history-sessions .list .text {
          margin: 0%;
          flex-basis: 30%;
          text-align: start;
          font-size: 1.1rem; }
          .session-section .content-container .history-sessions .list .text.parameter-warn {
            flex-basis: 5%;
            text-align: right; }
          .session-section .content-container .history-sessions .list .text.date {
            flex-basis: 30%; }
          .session-section .content-container .history-sessions .list .text.teachers {
            flex-basis: 45%; }
          .session-section .content-container .history-sessions .list .text.parameter {
            flex-basis: 30%; }
          .session-section .content-container .history-sessions .list .text .param-warn-icon {
            height: 15px;
            vertical-align: text-top;
            margin: 0;
            padding: 0; }
        .session-section .content-container .history-sessions .list .list-content {
          display: flex;
          flex-direction: column;
          grid-gap: 0.6rem;
          gap: 0.6rem;
          overflow-y: auto; }
          .session-section .content-container .history-sessions .list .list-content .element-box {
            background-color: #e8e8e8;
            border: solid 2px #e8e8e8;
            width: 100%;
            display: flex;
            align-items: center;
            margin: 0;
            padding: 1rem 0.8rem;
            border-radius: 0.2rem;
            font-weight: 400;
            cursor: pointer; }
            .session-section .content-container .history-sessions .list .list-content .element-box.selected {
              border: solid 2px #2764ff99 !important;
              background-color: #2764ff14; }
            .session-section .content-container .history-sessions .list .list-content .element-box .text {
              margin: 0;
              font-weight: 600;
              font-size: 1rem; }
          .session-section .content-container .history-sessions .list .list-content .element-box:hover {
            border: solid 2px #ccc; }

.modal-content {
  -webkit-animation: fadeIn 0.5s ease-in-out;
          animation: fadeIn 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  width: 100%;
  grid-gap: 1rem;
  gap: 1rem; }
  .modal-content .history-container {
    padding: 0; }
    .modal-content .history-container .history-list .box {
      grid-template-columns: 5% 50% 15% 25% 5%; }
      .modal-content .history-container .history-list .box .show-param-detail {
        display: flex;
        align-items: center;
        justify-content: center;
        grid-gap: 0.5rem;
        grid-gap: 0.5rem;
        gap: 0.5rem;
        cursor: normal;
        background-color: transparent;
        border-radius: 0.375rem;
        border: 2px solid #2764FF;
        height: 2.2rem; }
  .modal-content .confirm-button:disabled {
    opacity: 0.6;
    cursor: not-allowed; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@media screen and (min-width: 601px) and (max-width: 1199px) {
  .session-section .header-container .edit-session {
    width: 15rem;
    padding: 0.25rem; }
  .session-section .content-container .current-session .session-info {
    width: 100%;
    display: block; }
    .session-section .content-container .current-session .session-info .info-box {
      display: flex;
      justify-content: space-between;
      align-items: center; }
  .history-container .history-list .box {
    grid-template-columns: 6% 34% 30% 30%; }
  .modal-content .history-container {
    overflow-y: auto;
    padding-right: 1rem !important; }
    .modal-content .history-container .history-list {
      overflow-y: unset; }
      .modal-content .history-container .history-list .box {
        grid-template-columns: 5% 50% 15% 25% 5%; } }



.selected-session {
  margin-bottom: 2rem; }
  .selected-session .header-container {
    margin-bottom: 1rem;
    justify-content: start; }
    .selected-session .header-container .title {
      text-align: left;
      flex-grow: 1; }
    .selected-session .header-container .selected-session-btns {
      display: flex; }
  .selected-session .explanation-text {
    font-size: small;
    margin: 0;
    margin-bottom: 1.5rem;
    text-align: justify; }

@media screen and (min-width: 601px) and (max-width: 744px) {
  .selected-session .header-container .title {
    align-self: self-start; }
  .selected-session .header-container .selected-session-btns {
    flex-direction: column; }
    .selected-session .header-container .selected-session-btns button:nth-child(1) {
      margin-bottom: 1rem;
      margin-right: 0; } }

.modal {
  height: auto;
  top: auto;
  left: auto;
  bottom: auto;
  right: auto;
  display: flex; }

.loading-spinner {
  background: #fff;
  color: #2764FF;
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-items: center;
  align-self: center; }
  .loading-spinner .spinner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .loading-spinner .spinner-container .spinner {
      width: 2rem; }
  .loading-spinner svg {
    -webkit-animation: 2s linear infinite svg-animation;
    animation: 2s linear infinite svg-animation;
    max-width: 25px;
    margin-top: .5rem; }

@-webkit-keyframes svg-animation {
  0% {
    -webkit-transform: rotateZ(0deg);
    transform: rotateZ(0deg); }
  100% {
    -webkit-transform: rotateZ(360deg);
    transform: rotateZ(360deg); } }

@keyframes svg-animation {
  0% {
    -webkit-transform: rotateZ(0deg);
    transform: rotateZ(0deg); }
  100% {
    -webkit-transform: rotateZ(360deg);
    transform: rotateZ(360deg); } }
    .loading-spinner svg circle {
      -webkit-animation: 1.4s ease-in-out infinite both circle-animation;
              animation: 1.4s ease-in-out infinite both circle-animation;
      display: block;
      fill: transparent;
      stroke: #2764FF;
      stroke-linecap: round;
      stroke-dasharray: 283;
      stroke-dashoffset: 280;
      stroke-width: 10px;
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%; }

@-webkit-keyframes circle-animation {
  0%,
  25% {
    stroke-dashoffset: 280;
    -webkit-transform: rotate(0);
    transform: rotate(0); }
  50%,
  75% {
    stroke-dashoffset: 75;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg); }
  100% {
    stroke-dashoffset: 280;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes circle-animation {
  0%,
  25% {
    stroke-dashoffset: 280;
    -webkit-transform: rotate(0);
    transform: rotate(0); }
  50%,
  75% {
    stroke-dashoffset: 75;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg); }
  100% {
    stroke-dashoffset: 280;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.AdminSwitch {
  display: flex;
  flex-direction: column;
  flex-grow: 15;
  flex-basis: 100px;
  display: -webkit-flex;
  -webkit-flex-grow: 15;
  -webkit-flex-basis: 100px; }
  .AdminSwitch > div {
    height: 100%; }
    .AdminSwitch > div .AdminPanelProvider {
      height: 100%;
      overflow: hidden; }
      .AdminSwitch > div .AdminPanelProvider .AdminGlobal {
        height: 89%; }

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.goDown-enter {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-duration: 500ms;
          animation-duration: 500ms;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }

.goDown-exit {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
  -webkit-animation-duration: 1;
          animation-duration: 1;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }

@media screen and (min-width: 601px) and (max-width: 1199px) {
  .AdminSwitch > div .AdminPanelProvider {
    height: 100%;
    overflow: hidden;
    max-width: calc(100vw - 160px); } }

@media screen and (min-width: 1249px) and (max-width: 1310px) {
  .AdminSwitch > div .AdminPanelProvider {
    max-width: calc(100vw - 195px); } }

.Footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 3.75rem 1.57rem 3.75rem;
  flex-basis: 3rem; }
  .Footer .left {
    display: flex;
    text-align: left;
    align-content: center; }
  .Footer .right {
    display: flex;
    text-align: right;
    align-content: center; }
  .Footer .info {
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 1rem;
    line-height: 17px;
    display: flex;
    align-items: center;
    letter-spacing: 0.05em;
    color: #003a69;
    margin-left: 1rem; }
  .Footer a {
    display: flex;
    color: #003a69; }
    .Footer a img {
      margin-right: .5rem; }

@media screen and (min-width: 601px) and (max-width: 1199px) {
  .Footer {
    flex-basis: unset; } }

@media screen and (min-width: 1199px) and (max-height: 900px) {
  .Footer {
    margin-top: 3rem; } }

.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  flex-basis: 5rem;
  background-color: #002855;
  height: 100px;
  min-height: 100px;
  padding: 0 33px;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.3); }
  .Header .left {
    display: flex;
    text-align: left;
    height: 90%; }
  .Header .right {
    display: flex;
    text-align: right;
    align-items: center; }
    .Header .right .info {
      font-family: 'Rubik', sans-serif;
      font-style: italic;
      font-weight: 300;
      font-size: 20px;
      line-height: 24px;
      text-align: right; }
      .Header .right .info .team {
        font-weight: 700;
        margin-left: 0.32rem; }
    .Header .right .logout {
      font-family: 'Rubik', sans-serif;
      font-weight: 300;
      margin-left: 1.9rem;
      text-transform: uppercase;
      letter-spacing: 0.15em; }
    .Header .right .bocconi {
      margin-left: 2.5rem; }
  .Header .title {
    font-family: 'Roboto', sans-serif;
    font-style: italic;
    font-weight: 700;
    font-size: 40px;
    line-height: 47px;
    margin-left: 0.5rem; }
  .Header .logoArea {
    width: 305px; }
  .Header .flameWhite {
    height: 63px; }
  .Header .bocconi {
    height: 36px; }
  .Header .header-eurofire-logo {
    display: flex;
    align-items: center; }

@media screen and (min-width: 601px) and (max-width: 1199px) {
  .Header .bocconi {
    height: 26px; }
  .Header .flameWhite {
    height: 36px; }
  .Header .title {
    font-size: 26px; }
  .Header .logoArea {
    width: 255px; } }

.Admin {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 1.57rem 3.75rem;
  background-color: #f9f9f9;
  overflow: auto; }
  .Admin .Footer {
    padding: 0; }

.OverHeader {
  background-color: #002855;
  height: 100px;
  width: 100%;
  color: white;
  display: flex;
  padding: 0 33px;
  align-items: center;
  justify-content: space-between;
  z-index: 99999;
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 22px; }
  .OverHeader img {
    height: 37px; }
  .OverHeader p {
    margin: auto;
    padding-top: 20px; }
  .OverHeader .welcomeLearninglabLogo-container {
    margin: auto;
    margin-top: 39px; }
  .OverHeader .welcomeLearninglabLogo {
    height: 18px;
    margin: auto; }
  .OverHeader .learningLabText {
    color: #00A3E3;
    font-size: 26px;
    font-weight: bold; }
  .OverHeader .langs {
    display: inline-flex;
    color: #BBBBBB;
    align-items: center;
    font-size: 14px; }
    .OverHeader .langs .lang {
      padding: 0.5em;
      cursor: pointer; }
      .OverHeader .langs .lang.active {
        color: #01a3e2;
        font-weight: 500; }

@media screen and (min-width: 601px) and (max-width: 1199px) {
  .OverHeader {
    min-height: 70px; }
    .OverHeader img {
      height: 26px; } }
    @media screen and (min-width: 601px) and (max-width: 1199px) and (min-width: 601px) and (max-width: 700px) {
      .OverHeader img {
        height: 24px; } }

@media screen and (min-width: 601px) and (max-width: 1199px) {
    .OverHeader .welcomeLearninglabLogo-container {
      margin: 0;
      margin-top: auto;
      margin-bottom: auto;
      margin-left: 1rem; } }
    @media screen and (min-width: 601px) and (max-width: 1199px) and (min-width: 601px) and (max-width: 700px) {
      .OverHeader .welcomeLearninglabLogo-container {
        margin-right: 1rem; } }

@media screen and (min-width: 601px) and (max-width: 1199px) {
      .OverHeader .welcomeLearninglabLogo-container .welcomeLearninglabLogo {
        height: 13px;
        margin: auto; } }
      @media screen and (min-width: 601px) and (max-width: 1199px) and (min-width: 601px) and (max-width: 700px) {
        .OverHeader .welcomeLearninglabLogo-container .welcomeLearninglabLogo {
          height: 11px; } }

.TeacherInfoBox {
  display: flex;
  font-family: 'Rubik', 'sans-serif';
  justify-content: flex-end;
  margin-top: 1.8rem; }
  @media screen and (max-height: 52rem) {
    .TeacherInfoBox {
      margin-top: 0.5rem;
      justify-content: flex-end; } }
  .TeacherInfoBox .text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 1.5rem; }
    .TeacherInfoBox .text span:first-child {
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 19px;
      text-align: right;
      text-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25); }
    .TeacherInfoBox .text span:nth-child(2) {
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 1px;
      text-align: right; }

.TokenInput {
  /* LOGIN */
  font-family: 'Rubik', 'sans-serif';
  font-style: normal;
  display: flex;
  align-items: stretch;
  color: #fff;
  flex-direction: column;
  text-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25); }
  .TokenInput .button-empty-white.login,
  .TokenInput .inputField-container {
    font-family: 'Rubik', 'sans-serif';
    font-style: normal;
    font-weight: 300;
    font-size: 1.5rem;
    line-height: 38px;
    text-align: center;
    letter-spacing: 0.15em;
    text-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
    text-transform: uppercase;
    border: 1px solid #fff;
    padding: 0rem 3rem;
    justify-content: space-between; }
  .TokenInput .button-empty-white.login {
    max-width: 30rem; }
  .TokenInput .button-empty-white,
  .TokenInput .inputField-container {
    border-radius: 5px; }
  .TokenInput .WhiteButton {
    min-width: 350px;
    z-index: 999; }
    .TokenInput .WhiteButton ::-webkit-input-placeholder {
      color: #fff;
      opacity: 1; }
    .TokenInput .WhiteButton ::placeholder {
      color: #fff;
      opacity: 1; }
    .TokenInput .WhiteButton ::-ms-input-placeholder {
      color: #fff; }
  .TokenInput .inputField {
    /* Inserisci qui il tuo token */
    font-family: 'Rubik', 'sans-serif';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px; }
    .TokenInput .inputField input {
      font-size: 1rem; }
  .TokenInput .inputField-container {
    display: flex;
    padding: 0; }
    .TokenInput .inputField-container input {
      color: #fff; }
  .TokenInput .downArrowWhite {
    background-color: #fff;
    width: 78px;
    height: 62px;
    border-radius: 0 3px 3px 0;
    border: 1px solid #fff;
    align-items: center; }
    .TokenInput .downArrowWhite.disabled {
      opacity: 0.6;
      cursor: not-allowed; }
  .TokenInput .iconArrow {
    height: 30px;
    width: 30px;
    display: flex;
    margin: auto auto;
    margin-top: 1rem; }
  .TokenInput .error-message {
    margin-top: 1rem;
    font-size: 16px;
    line-height: 22px; }

@media screen and (min-width: 601px) and (max-width: 1199px) {
  .TokenInput .downArrowWhite {
    width: 62px !important;
    height: 45px !important; }
  .TokenInput .iconArrow {
    height: 24px !important;
    width: 24px !important;
    margin-top: 0.75rem !important; } }

.WelcomeHeader {
  display: inline-block;
  width: 50%;
  vertical-align: top; }
  .WelcomeHeader * {
    vertical-align: bottom; }
  .WelcomeHeader.bright .title {
    color: #fff; }
  .WelcomeHeader.bright .subtitle {
    color: #fff; }
  .WelcomeHeader .title {
    font-family: 'Roboto', 'sans-serif';
    font-style: italic;
    font-weight: bold;
    font-size: 5rem;
    line-height: 5.8rem;
    text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25); }
  .WelcomeHeader .subtitle {
    font-family: 'Rubik', 'sans-serif';
    font-style: italic;
    font-weight: 300;
    font-size: 26px;
    line-height: 31px;
    text-align: right;
    text-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25); }
  .WelcomeHeader .flameWhite {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
    margin-right: .5rem; }

@media screen and (min-width: 601px) and (max-width: 1199px) {
  .WelcomeHeader {
    display: contents;
    width: 50%;
    vertical-align: top; }
    .WelcomeHeader .title {
      font-size: 3.5rem;
      line-height: 4rem; }
    .WelcomeHeader .subtitle {
      font-size: 18px;
      line-height: 24px; }
    .WelcomeHeader .flameWhite {
      width: 7%; } }

.Login {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: radial-gradient(790.87px at 58.69% 39.44%, #2764FF 0%, #003a69 100%);
  overflow-y: auto;
  text-align: left;
  color: #fff;
  position: relative; }
  .Login .Login-main {
    padding: 16px 57px; }
    @media screen and (min-width: 601px) and (max-width: 660px) {
      .Login .Login-main {
        padding: 16px 29px; } }
  .Login .welcomePageMainImage {
    position: absolute;
    left: 7rem;
    right: 36.56%;
    bottom: 0;
    width: 30%;
    max-width: 720px; }
    @media screen and (max-height: 699px) {
      .Login .welcomePageMainImage {
        display: none; } }
    @media screen and (min-width: 601px) and (max-width: 991px) {
      .Login .welcomePageMainImage {
        display: none; } }
    @media screen and (min-width: 992px) and (max-width: 1199px) {
      .Login .welcomePageMainImage {
        width: 40%; } }
  .Login .llabLogoWhite {
    position: absolute;
    bottom: 4rem;
    right: 4rem; }
    @media screen and (max-width: 93.75rem) {
      .Login .llabLogoWhite {
        right: 2rem; } }
  .Login .logoAreaWelcome {
    height: 66px;
    right: 57px;
    top: 12.5px;
    position: absolute; }
  .Login .TokenInput {
    margin-top: 3rem; }
    @media screen and (max-height: 50rem) {
      .Login .TokenInput {
        margin-top: 1.5rem; } }
  .Login .TokenInput .button-empty-white.login {
    min-height: 63px;
    display: flex;
    padding: 0;
    font-family: 'Rubik', 'sans-serif';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-transform: none; }
    .Login .TokenInput .button-empty-white.login * {
      vertical-align: middle; }
    .Login .TokenInput .button-empty-white.login span {
      padding: 0.5rem; }
  .Login .WelcomeHeader {
    z-index: 999; }
  .Login .teacherInfoBoxes-container {
    margin-top: -12rem; }
    @media screen and (min-height: 601px) and (max-height: 699px) {
      .Login .teacherInfoBoxes-container {
        margin-top: -19rem; } }
    @media screen and (min-width: 601px) and (max-width: 660px) {
      .Login .teacherInfoBoxes-container {
        margin-top: 2rem;
        position: absolute;
        right: 2rem !important;
        bottom: 12rem; } }
  .Login .blueRectangle {
    width: 415px;
    height: 100px;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: right;
    position: absolute;
    right: 0;
    bottom: 25px; }
    @media screen and (max-height: 600px) {
      .Login .blueRectangle {
        width: 295px;
        height: 70px;
        bottom: -15rem; } }

/* reset css input */
input {
  border: none;
  background-image: none;
  background-color: transparent;
  box-shadow: none; }

@media screen and (min-width: 601px) and (max-width: 1199px) {
  .Login .TokenInput {
    margin-top: 2rem;
    padding-left: 0; }
  .Login .TokenInput .button-empty-white.login {
    min-height: 46px;
    font-size: 16px;
    max-width: 25rem; }
  .Login .teacherInfoBoxes-container {
    margin-top: 2rem;
    position: absolute;
    right: 4rem;
    bottom: 12rem; } }
  @media screen and (min-width: 601px) and (max-width: 1199px) and (max-height: 601px) {
    .Login .teacherInfoBoxes-container {
      position: -webkit-sticky;
      position: sticky;
      margin-top: -8rem; } }

@media screen and (min-width: 601px) and (max-width: 1199px) and (max-height: 600px) {
  .Login .logoAreaWelcome {
    height: 50px;
    right: 30px;
    top: 7px;
    position: absolute; } }

.TokenInput {
  /* LOGIN */
  font-family: 'Rubik', 'sans-serif';
  font-style: normal;
  display: flex;
  align-items: stretch;
  color: #fff;
  flex-direction: column;
  text-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25); }
  .TokenInput .button-empty-white.login,
  .TokenInput .inputField-container {
    font-family: 'Rubik', 'sans-serif';
    font-style: normal;
    font-weight: 300;
    font-size: 1.5rem;
    line-height: 38px;
    text-align: center;
    letter-spacing: 0.15em;
    text-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
    text-transform: uppercase;
    border: 1px solid #fff;
    padding: 0rem 3rem;
    justify-content: space-between; }
  .TokenInput .button-empty-white.login {
    max-width: 30rem; }
  .TokenInput .button-empty-white,
  .TokenInput .inputField-container {
    border-radius: 5px; }
  .TokenInput .WhiteButton {
    min-width: 350px;
    z-index: 999; }
    .TokenInput .WhiteButton ::-webkit-input-placeholder {
      color: #fff;
      opacity: 1; }
    .TokenInput .WhiteButton ::placeholder {
      color: #fff;
      opacity: 1; }
    .TokenInput .WhiteButton ::-ms-input-placeholder {
      color: #fff; }
  .TokenInput .inputField {
    /* Inserisci qui il tuo token */
    font-family: 'Rubik', 'sans-serif';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px; }
    .TokenInput .inputField input {
      font-size: 1rem; }
  .TokenInput .inputField-container {
    display: flex;
    padding: 0; }
    .TokenInput .inputField-container input {
      color: #fff; }
  .TokenInput .downArrowWhite {
    background-color: #fff;
    width: 78px;
    height: 62px;
    border-radius: 0 3px 3px 0;
    border: 1px solid #fff;
    align-items: center; }
  .TokenInput .iconArrow {
    height: 30px;
    width: 30px;
    display: flex;
    margin: auto auto;
    margin-top: 1rem; }
  .TokenInput .error-message {
    margin-top: 1rem;
    font-size: 16px;
    line-height: 22px; }

.lowres {
  flex-direction: column;
  /* da video-gallery */
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: radial-gradient(790.87px at 58.69% 39.44%, #2764FF 0%, #003a69 100%);
  background-repeat: repeat;
  display: flex;
  color: #2764FF;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999; }
  .lowres .WelcomeHeader {
    flex: 1 1;
    position: relative;
    color: white;
    width: 100%; }
    .lowres .WelcomeHeader .title {
      font-size: 3.6rem;
      line-height: 3.6rem; }
    .lowres .WelcomeHeader .flameWhite {
      width: 3.6rem; }
    .lowres .WelcomeHeader .subtitle {
      font-size: 1.5rem;
      line-height: 0px; }
  .lowres .lowres-logo-container {
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: center; }
  .lowres .SDALink {
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: center; }
  .lowres .row {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    justify-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    margin: 0 auto;
    -webkit-transform: translateZ(0);
    transform: translateZ(0); }
    .lowres .row .col {
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;
      justify-items: center;
      flex-wrap: wrap;
      flex-direction: column;
      flex-grow: 1;
      height: 100%;
      -webkit-transform: translateZ(0);
      transform: translateZ(0); }
  @media print {
    .lowres {
      display: none; } }
  .lowres .lowres-box {
    display: flex;
    flex: 1 1; }
    .lowres .lowres-box .lowres-box-top {
      background-color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%; }
      .lowres .lowres-box .lowres-box-top .row {
        place-content: center;
        place-items: center; }
        .lowres .lowres-box .lowres-box-top .row + .row {
          margin-top: 1vh; }
      .lowres .lowres-box .lowres-box-top .col {
        place-content: center;
        place-items: center;
        max-width: 75%; }
        .lowres .lowres-box .lowres-box-top .col a {
          display: flex;
          place-content: center;
          place-items: center;
          width: auto;
          margin: 1vh 0 0 0; }
        .lowres .lowres-box .lowres-box-top .col hr {
          border-color: #fff;
          margin: 1vh 0 1vh 0; }
        .lowres .lowres-box .lowres-box-top .col h1 {
          font-size: 7vh;
          margin: 2vh 0 .5vh 0;
          transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94); }
        .lowres .lowres-box .lowres-box-top .col h3 {
          font-size: 4vh;
          color: #2764FF;
          margin: .5rem 0;
          transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94); }
        .lowres .lowres-box .lowres-box-top .col h4 {
          font-size: 4vh;
          font-weight: lighter;
          margin: .5vh 0 4vh 0;
          transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94); }
        @media (max-width: 37.5rem) {
          .lowres .lowres-box .lowres-box-top .col h1 {
            font-size: 5vh; }
          .lowres .lowres-box .lowres-box-top .col h3 {
            font-size: 3vh; }
          .lowres .lowres-box .lowres-box-top .col h4 {
            font-size: 3vh; } }
        @media (max-height: 37.5rem) {
          .lowres .lowres-box .lowres-box-top .col h1 {
            font-size: 5vw; }
          .lowres .lowres-box .lowres-box-top .col h3 {
            font-size: 3vw; }
          .lowres .lowres-box .lowres-box-top .col h4 {
            font-size: 3vw; } }
      .lowres .lowres-box .lowres-box-top img {
        width: 50%; }
      @media (max-height: 23.125rem) {
        .lowres .lowres-box .lowres-box-top .icon {
          display: none; } }
    .lowres .lowres-box .lowres-box-bottom {
      padding: 2vh 1vh;
      background-color: #fff;
      border-bottom-left-radius: 0.625rem;
      border-bottom-right-radius: 0.625rem; }
      .lowres .lowres-box .lowres-box-bottom .col {
        place-content: center;
        place-items: center; }
        .lowres .lowres-box .lowres-box-bottom .col a {
          display: flex;
          place-content: center;
          place-items: center;
          width: auto;
          max-width: 75%; }
          .lowres .lowres-box .lowres-box-bottom .col a img {
            width: 100%;
            height: auto; }

.Onboarding {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  overflow-y: auto; }
  .Onboarding div.add-row-members {
    color: #39CB85;
    font-family: 'Rubik', 'sans-serif';
    font-weight: 500;
    font-size: 2rem;
    background-color: rgba(57, 203, 133, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    border-radius: 4px;
    cursor: pointer; }
  .Onboarding .content {
    padding: 0 3.75rem;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 10px; }
  .Onboarding h2 {
    font-family: 'Rubik', 'sans-serif';
    color: #2764FF;
    text-align: left;
    font-weight: 300;
    font-size: 1.875rem;
    margin-top: 1rem; }
  .Onboarding .Card {
    position: relative;
    padding: 3rem 5rem;
    height: auto;
    flex-grow: 1;
    flex-basis: 10px; }
    .Onboarding .Card .members-container {
      height: 100%;
      overflow: auto; }
      @media screen and (max-height: 601px) {
        .Onboarding .Card .members-container h4 {
          text-align: left; } }
      .Onboarding .Card .members-container .validation-message {
        text-align: left;
        color: #CF0000;
        height: auto;
        padding: 0.5rem;
        margin-bottom: 2rem; }
    .Onboarding .Card div.remove-row-members {
      color: #CF0000;
      font-family: 'Rubik', 'sans-serif';
      font-weight: 500;
      font-size: 2rem;
      background-color: rgba(207, 0, 0, 0.4);
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 3rem;
      width: 3rem;
      height: 3rem;
      border-radius: 4px;
      cursor: pointer; }
    .Onboarding .Card .ui.large.label {
      margin-right: 0.5rem;
      margin-left: 1rem; }
    .Onboarding .Card .button-empty-white {
      color: #39CB85; }
      .Onboarding .Card .button-empty-white.disabled {
        border-color: #ccc;
        color: #ccc;
        cursor: not-allowed; }
    .Onboarding .Card .grid-container {
      display: grid;
      grid-column-gap: 1rem;
      /*   grid-template-columns: auto auto auto; */
      height: 100%; }
      .Onboarding .Card .grid-container .grid-item {
        padding: 0.5em;
        text-align: left;
        display: flex;
        align-items: center;
        /* .third:nth-of-type(1){
        .label{
          width: 30%;
        }
      }

      .third:nth-of-type(2){
        .label{
          width: 30%;
        }
      } */ }
        .Onboarding .Card .grid-container .grid-item b {
          font-family: 'Rubik', 'sans-serif';
          color: #333;
          font-weight: 500;
          font-size: 1.6rem;
          width: 45px; }
        .Onboarding .Card .grid-container .grid-item .third {
          width: 33%;
          display: flex;
          align-items: center;
          justify-content: flex-start; }
          .Onboarding .Card .grid-container .grid-item .third .label {
            width: 6rem;
            justify-content: center; }
        .Onboarding .Card .grid-container .grid-item .third:nth-of-type(3) input {
          margin-right: 2rem; }
      .Onboarding .Card .grid-container .item-full {
        /* grid-column-start: 1;
      grid-column-end: 4; */ }
      .Onboarding .Card .grid-container .item-half {
        /*  grid-column-start: 1;
      grid-column-end: 3; */ }
      .Onboarding .Card .grid-container input {
        border: 1px solid #ccc;
        box-sizing: border-box;
        border-radius: 4px;
        height: 3rem;
        padding: 0 .7rem;
        font-family: 'Rubik', 'sans-serif';
        color: #333;
        font-weight: 300;
        font-size: 1.125rem; }
        .Onboarding .Card .grid-container input.invalid {
          border-color: #CF0000; }
      .Onboarding .Card .grid-container .label {
        height: 3rem;
        line-height: 1.7rem;
        color: #e8e8e8;
        font-family: 'Rubik', 'sans-serif';
        color: rgba(51, 51, 51, 0.8);
        font-weight: 500;
        font-size: 0.875rem;
        padding: 0.75em;
        text-align: left;
        display: flex; }
      .Onboarding .Card .grid-container .confirm-container {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: left; }
        .Onboarding .Card .grid-container .confirm-container .warning {
          background-color: #fff6d4;
          border: 2px solid #D8A930;
          border-radius: 0.375rem;
          display: flex;
          grid-gap: 0.8rem;
          gap: 0.8rem;
          padding: 0.4rem 2rem;
          margin-left: 0.5em;
          cursor: default; }
          .Onboarding .Card .grid-container .confirm-container .warning .text {
            font-size: 1rem;
            font-weight: 700;
            color: #D8A930; }
    .Onboarding .Card .button-empty-white.disabled {
      border-color: #ccc;
      color: #ccc;
      cursor: not-allowed; }
    .Onboarding .Card .modal {
      font-family: 'Rubik', 'sans-serif';
      background: #fff;
      box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      position: absolute;
      padding: .5rem 2rem 2rem 2rem;
      bottom: 2rem;
      left: 25rem;
      max-width: 32rem; }
      .Onboarding .Card .modal .close-container {
        margin-left: 28.5em;
        text-align: right; }
        .Onboarding .Card .modal .close-container img {
          cursor: pointer; }
      .Onboarding .Card .modal span {
        margin-bottom: 0.5rem; }
      .Onboarding .Card .modal .button-empty-white {
        margin-top: 1.5rem;
        box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.15);
        border: 0;
        cursor: pointer; }
    .Onboarding .Card .onboarding-popup {
      display: flex;
      flex-direction: column; }
      .Onboarding .Card .onboarding-popup .close-container {
        margin-left: 0; }

@media screen and (min-width: 601px) and (max-width: 1199px) {
  .Onboarding .Card .grid-container .third {
    width: 100% !important; } }

@media screen and (max-height: 600px) {
  .Onboarding .content {
    display: block; }
  .Onboarding .Card .members-container {
    height: auto; } }

.Player {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  overflow-y: auto;
  overflow-x: hidden; }

@media screen and (min-width: 601px) and (max-width: 1199px) {
  .Player {
    min-height: 100vh;
    height: auto; } }

.ChaptersMenu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-basis: 5rem; }
  .ChaptersMenu .menu-item {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 1px 1px 40px rgba(0, 0, 0, 0.2);
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 28px;
    line-height: 30px;
    margin-bottom: 0.5rem; }
    .ChaptersMenu .menu-item.first-time {
      box-shadow: 1px 1px 40px rgba(39, 100, 255, 0.4);
      border: 1px solid #2764FF;
      color: #2764FF !important; }

.ChaptersMenu-tooltip-avatar {
  display: flex;
  padding: 1rem;
  align-items: flex-start;
  min-width: 25rem; }
  .ChaptersMenu-tooltip-avatar .image {
    margin-right: 1rem;
    min-width: 3.5rem;
    max-height: 7rem; }

.DocumentPreview {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 2;
  background: #fff;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin: 0.5rem;
  min-width: 33%;
  max-width: 100%; }
  .DocumentPreview > .pdf-container {
    margin-bottom: 2rem;
    width: 80%;
    height: 100%;
    min-width: 100%; }

@media screen and (min-width: 601px) and (max-width: 1199px) {
  .DocumentPreview {
    min-height: 70vh;
    min-width: 70%; }
    .DocumentPreview > .pdf-container {
      margin-bottom: 2rem;
      width: 100%; } }

.SandyIntroduction-tooltip {
  display: flex;
  padding: 1rem;
  align-items: flex-start;
  min-width: 25rem; }

.SandyIntroduction {
  overflow: hidden;
  width: 30em;
  text-align: right;
  position: relative;
  height: 100%; }
  .SandyIntroduction > img {
    width: 500px;
    margin-top: 10rem; }

@media screen and (min-width: 601px) and (max-width: 991px) {
  .SandyIntroduction > img {
    width: 400px;
    margin-top: 0;
    margin-left: -6rem; } }

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .SandyIntroduction > img {
    width: 450px;
    margin-top: 0; } }

.VideoPlayer {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  background: #fff;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin: 0.5rem;
  overflow: hidden;
  align-self: stretch; }
  .VideoPlayer .content {
    overflow-y: auto;
    flex-grow: 1; }

.Prologue {
  display: flex;
  overflow: auto;
  align-content: space-between;
  justify-content: space-between;
  background: transparent;
  box-shadow: none;
  margin-top: 0; }
  .Prologue .left {
    width: 66%;
    display: inline-flex; }
  .Prologue .right {
    display: inline-flex;
    flex-direction: column;
    max-width: 33%;
    width: 33%;
    overflow: hidden; }
    .Prologue .right .DocumentPreview {
      display: flex;
      overflow: hidden; }

@media screen and (min-width: 601px) and (max-width: 1199px) {
  .Prologue {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    min-width: 100%; } }
  @media screen and (min-width: 601px) and (max-width: 1199px) and (max-height: 600px) {
    .Prologue {
      overflow-y: auto;
      min-height: 100vh; } }
  @media screen and (min-width: 601px) and (max-width: 1199px) and (min-width: 992px) and (max-width: 1199px) {
    .Prologue {
      justify-content: flex-start !important; } }

@media screen and (min-width: 601px) and (max-width: 1199px) {
    .Prologue .left {
      width: 100%;
      height: 100%;
      display: flex;
      flex-grow: unset; } }
    @media screen and (min-width: 601px) and (max-width: 1199px) and (max-height: 600px) {
      .Prologue .left {
        height: 100%; } }
    @media screen and (min-width: 601px) and (max-width: 1199px) and (min-width: 992px) and (max-width: 1199px) {
      .Prologue .left {
        width: 100% !important; } }

@media screen and (min-width: 601px) and (max-width: 1199px) {
    .Prologue .right {
      display: flex;
      flex-direction: column;
      max-width: none;
      width: 100%;
      height: 100%;
      overflow: visible; } }
    @media screen and (min-width: 601px) and (max-width: 1199px) and (max-height: 600px) {
      .Prologue .right {
        height: 100%; } }
    @media screen and (min-width: 601px) and (max-width: 1199px) and (min-width: 992px) and (max-width: 1199px) {
      .Prologue .right {
        width: 100% !important; } }

@media screen and (min-width: 1200px) and (max-width: 1248px) {
  .Prologue {
    min-width: 100%; } }

.Uploader {
  margin-right: 1rem; }
  .Uploader .Card {
    padding-bottom: 0.1rem;
    max-height: calc(100vh - 155px);
    display: flex;
    flex-direction: column; }
  .Uploader .upload-spinner-container {
    display: flex;
    justify-content: center;
    align-items: center; }
    .Uploader .upload-spinner-container .upload-spinner {
      width: 2rem;
      margin-right: 1rem; }
  .Uploader button {
    min-width: 15rem;
    height: 3.125rem;
    background: #FFFFFF;
    box-shadow: 0.0625rem 0.0625rem 0.5rem rgba(0, 0, 0, 0.15);
    border-radius: 0.3125rem;
    border-color: transparent;
    font-family: Rubik;
    font-style: normal;
    font-weight: 300;
    font-size: 1.25rem;
    line-height: 1.5rem;
    text-align: center;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    color: #39CB85;
    cursor: pointer; }
    .Uploader button.orange {
      color: #FF8744; }
    .Uploader button.reset {
      margin: 1rem; }
  .Uploader .drop-zone-container {
    display: flex;
    flex-grow: 1;
    flex-basis: 23rem; }
    .Uploader .drop-zone-container .drop-zone {
      border: 1px dashed #ccc;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 1rem;
      width: 100%; }
      .Uploader .drop-zone-container .drop-zone .upload-title span {
        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 36px;
        text-align: center;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #ccc; }
      .Uploader .drop-zone-container .drop-zone .upload-subtitle {
        margin: 3.25rem 0; }
        .Uploader .drop-zone-container .drop-zone .upload-subtitle span {
          font-family: Rubik;
          font-style: normal;
          font-weight: 300;
          font-size: 22px;
          line-height: 24px;
          text-align: center;
          letter-spacing: 0.05em;
          color: #ccc; }
      .Uploader .drop-zone-container .drop-zone .upload-error {
        margin: 3.25rem 0 0 0; }
        .Uploader .drop-zone-container .drop-zone .upload-error span {
          font-family: Rubik;
          font-style: normal;
          font-weight: 300;
          font-size: 1.125rem;
          line-height: 1.125rem;
          text-align: center;
          letter-spacing: 0.05em;
          color: #CF0000;
          opacity: .5;
          text-transform: uppercase; }
  .Uploader .file-selected-wrapper {
    border: 1px dashed #ccc;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 23rem;
    margin: 1rem;
    flex-grow: 1; }
    .Uploader .file-selected-wrapper .upload-filename {
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 1.25rem;
      line-height: 1.6875rem;
      display: flex;
      align-items: center;
      text-align: center; }
    .Uploader .file-selected-wrapper .upload-filesize {
      font-family: Rubik;
      font-style: normal;
      font-weight: 300;
      font-size: 20px;
      line-height: 27px;
      display: flex;
      align-items: center;
      text-align: center; }
    .Uploader .file-selected-wrapper .upload-status {
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 1.25rem;
      line-height: 1.5rem;
      text-align: center;
      letter-spacing: 0.1em;
      color: #39CB85;
      margin-top: .5rem; }
      .Uploader .file-selected-wrapper .upload-status.orange {
        color: #FF8744; }
      .Uploader .file-selected-wrapper .upload-status.fail {
        color: #CF0000;
        cursor: pointer; }
    .Uploader .file-selected-wrapper button {
      background: #39CB85;
      color: #fff; }
      .Uploader .file-selected-wrapper button.orange {
        background: #FF8744; }
      .Uploader .file-selected-wrapper button svg {
        -webkit-animation: 2s linear infinite svg-animation;
        animation: 2s linear infinite svg-animation;
        max-width: 25px;
        margin-top: .5rem; }

@-webkit-keyframes svg-animation {
  0% {
    -webkit-transform: rotateZ(0deg);
    transform: rotateZ(0deg); }
  100% {
    -webkit-transform: rotateZ(360deg);
    transform: rotateZ(360deg); } }

@keyframes svg-animation {
  0% {
    -webkit-transform: rotateZ(0deg);
    transform: rotateZ(0deg); }
  100% {
    -webkit-transform: rotateZ(360deg);
    transform: rotateZ(360deg); } }
        .Uploader .file-selected-wrapper button svg circle {
          -webkit-animation: 1.4s ease-in-out infinite both circle-animation;
                  animation: 1.4s ease-in-out infinite both circle-animation;
          display: block;
          fill: transparent;
          stroke: #fff;
          stroke-linecap: round;
          stroke-dasharray: 283;
          stroke-dashoffset: 280;
          stroke-width: 10px;
          -webkit-transform-origin: 50% 50%;
                  transform-origin: 50% 50%; }

@-webkit-keyframes circle-animation {
  0%,
  25% {
    stroke-dashoffset: 280;
    -webkit-transform: rotate(0);
    transform: rotate(0); }
  50%,
  75% {
    stroke-dashoffset: 75;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg); }
  100% {
    stroke-dashoffset: 280;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes circle-animation {
  0%,
  25% {
    stroke-dashoffset: 280;
    -webkit-transform: rotate(0);
    transform: rotate(0); }
  50%,
  75% {
    stroke-dashoffset: 75;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg); }
  100% {
    stroke-dashoffset: 280;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }
  .Uploader .uploader-text-container {
    min-height: 3rem; }
    @media screen and (max-height: 44rem) {
      .Uploader .uploader-text-container {
        min-height: 7rem; } }

@media screen and (min-width: 601px) and (max-width: 1199px) {
  .Uploader .Card {
    max-height: 100vh; } }

.Epilogue {
  max-height: calc(100vh - 300px);
  display: flex;
  flex-grow: 1;
  margin: 0.5rem; }
  .Epilogue > .Uploader {
    flex-grow: 1;
    flex-basis: 100px; }

.link-to-thanks {
  display: flex;
  align-content: flex-end;
  justify-content: flex-end;
  align-items: flex-end;
  justify-items: flex-end;
  margin: 2rem 1rem;
  text-transform: uppercase; }
  .link-to-thanks a {
    width: 12.5rem;
    height: 1.875rem;
    background: #fff;
    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 1.875rem;
    text-align: center;
    letter-spacing: 0.1em;
    color: #39CB85; }

@media screen and (min-width: 601px) and (max-width: 1199px) {
  .Epilogue {
    display: contents; }
    .Epilogue > .Uploader {
      margin-bottom: 3rem; } }

.SectionDocuments {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 33% 33% 33%;
  margin: 0.5rem 0;
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 3rem; }
  .SectionDocuments > a {
    background: #fff;
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.1);
    border-radius: 5px; }
  .SectionDocuments + .Card {
    overflow-x: hidden;
    display: flex;
    flex-direction: column; }

@media screen and (min-width: 601px) and (max-width: 1199px) {
  .SectionDocuments {
    display: flex;
    overflow-x: auto;
    padding: 1rem 0; } }

.InputWrapper {
  /* Chrome, Safari, Edge, Opera */
  /* Firefox */ }
  .InputWrapper .ui.label.decimals {
    padding: 0.2rem;
    font-size: 1.1rem !important; }
  .InputWrapper input::-webkit-outer-spin-button,
  .InputWrapper input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; }
  .InputWrapper input[type=number] {
    -moz-appearance: textfield; }

.Kpi {
  display: flex;
  flex-direction: column;
  height: 100%; }
  .Kpi .ui.input {
    height: 100%; }
  .Kpi .label {
    text-align: left;
    padding-bottom: 0.4rem; }
  .Kpi .input-ele {
    border-left: 1px solid #E8FFEE; }
  .Kpi .decimals {
    background-color: transparent;
    border: 1px solid rgba(34, 36, 38, 0.15);
    width: 36px;
    text-align: center; }
  .Kpi .basic {
    width: 36px; }
  .Kpi .output {
    font-weight: bold; }
  .Kpi .basic + .input-ele {
    max-width: calc(100% - 72px) !important; }
  .Kpi .input-ele {
    max-width: calc(100% - 36px) !important; }
  .Kpi.active.correct .unit, .Kpi.active.correct .input-ele {
    background-color: #E8FFEE;
    border: 1px solid #39CB85; }
  .Kpi.active.correct .decimals {
    background-color: #39CB85; }
  .Kpi.active.wrong .unit, .Kpi.active.wrong .input-ele {
    background-color: #FFE5E5;
    border: 1px solid #E94040; }
  .Kpi.active.wrong .decimals {
    background-color: #E94040; }
  .Kpi.correct .unit, .Kpi.correct .input-ele {
    background-color: #fff; }
  .Kpi.correct .decimals {
    background-color: #fff;
    border: 1px solid #39CB85; }
  .Kpi.correct .input-ele {
    border: 1px solid #39CB85;
    opacity: 1; }
  .Kpi.correct .unit {
    border: 1px solid #39CB85;
    border-left: #39CB85; }
  .Kpi.correct .basic.label {
    border: 1px solid #39CB85; }
  .Kpi.correct .output {
    color: #39CB85; }
  .Kpi.wrong .unit, .Kpi.wrong .input-ele {
    background-color: #fff;
    border: 1px solid #E94040; }
  .Kpi.wrong .decimals {
    background-color: #fff;
    border: 1px solid #E94040; }
  .Kpi.wrong .input-ele {
    opacity: 1; }
  .Kpi.wrong .basic.label {
    border: 1px solid #E94040; }
  .Kpi.wrong .output {
    color: #E94040; }

.Matrix {
  overflow-x: auto;
  flex-grow: 1;
  flex-basis: 100px; }
  .Matrix .notes-active {
    border-bottom: 5px solid #FF8744;
    border-radius: 2.5px; }
  .Matrix .notes-active-reverse {
    border-left: 5px solid #FF8744;
    border-radius: 2.5px;
    height: 100%;
    position: absolute;
    top: 0;
    left: -7px; }
  .Matrix .matrix-title.noPointer {
    cursor: text; }
  .Matrix .matrix-indexes {
    text-transform: uppercase; }

@media screen and (min-width: 601px) and (max-width: 1199px) {
  .Matrix {
    flex-basis: unset; } }

.MatrixProvider {
  flex-grow: 1; }

.ActionButton {
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  width: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.2rem 1rem;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.1em;
  margin: 2rem;
  border: 1px solid transparent; }
  .ActionButton.clickable:hover {
    cursor: pointer; }

.ChapterNotes {
  display: flex;
  margin: 0.5rem;
  margin-right: 1.75rem;
  margin-left: 0;
  position: relative;
  align-items: flex-start; }
  .ChapterNotes .textarea-container {
    flex-grow: 1; }
  .ChapterNotes textarea {
    border: 1px solid #ccc;
    border-radius: 4px;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    color: #333;
    resize: none;
    height: 7rem;
    padding: .5rem;
    width: 100%; }
    .ChapterNotes textarea:focus {
      background: white;
      z-index: 9999; }
  .ChapterNotes .Placeholder {
    position: absolute;
    pointer-events: none;
    top: 0.5625rem;
    left: 7rem;
    font-size: 16px;
    line-height: 19px; }
    .ChapterNotes .Placeholder b {
      color: #FF8744;
      font-weight: 500; }
  .ChapterNotes .ActionButton {
    text-transform: uppercase;
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    margin: 0; }
    .ChapterNotes .ActionButton.clickable:hover {
      border-color: #FF8744; }
  .ChapterNotes .SquareButton {
    box-shadow: rgba(255, 135, 68, 0.3) 0px 2px 30px; }
  .ChapterNotes .button-empty-white {
    color: #39CB85; }

@media screen and (min-width: 601px) and (max-width: 1199px) {
  .ChapterNotes .ActionButton {
    background-color: white; }
  .ChapterNotes .modal {
    position: absolute;
    bottom: 2rem;
    left: 15%;
    right: 15%;
    margin-left: auto;
    margin-right: auto;
    width: 100%; }
    .ChapterNotes .modal .close-container {
      margin-left: 0; } }

.ChapterMain {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0 0.5rem;
  min-height: 0; }
  .ChapterMain .matrix {
    padding: 0;
    margin: 0.5rem 21px;
    padding-bottom: 1rem; }

@media screen and (min-width: 601px) and (max-width: 1199px) {
  .ChapterMain {
    min-height: none; } }

.ChapterDocuments {
  display: flex;
  height: 100%;
  max-width: calc(100vw - 170px);
  overflow-x: auto; }
  .ChapterDocuments .VideoPlayer {
    width: auto;
    min-width: 66%; }

@media screen and (min-width: 601px) and (max-width: 1199px) {
  .ChapterDocuments {
    display: flex;
    width: 100%;
    height: 100%;
    max-width: calc(100vw - 140px);
    overflow-x: auto;
    overflow-y: hidden; }
    .ChapterDocuments .VideoPlayer {
      min-width: 70%; } }


.modal-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #0000001c; }

.modal.Phase4OfferModal {
  color: black;
  max-width: none;
  text-align: left;
  height: 65vh;
  width: 80vw;
  top: 15rem;
  left: 11rem;
  z-index: 9999;
  background-image: url(https://learninglab.sdabocconi.it/files/eurofire_material/phase4OfferFooterImage.png);
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover; }
  .modal.Phase4OfferModal h1 {
    margin: 0;
    margin-bottom: 0.75rem; }
  .modal.Phase4OfferModal .close-container {
    margin-left: 0;
    text-align: left;
    position: relative; }
    .modal.Phase4OfferModal .close-container img.close {
      position: absolute;
      right: 1.6rem;
      top: 0;
      border: 1px solid black;
      padding: 0.5rem; }
  .modal.Phase4OfferModal .content {
    background-color: #ffffff9e;
    width: 70%;
    padding: 2rem 1.15rem;
    box-sizing: border-box; }
  .modal.Phase4OfferModal .phase4Offer-popup-other-message {
    margin-bottom: 0.75rem; }
  .modal.Phase4OfferModal .email-phase4Offer-body,
  .modal.Phase4OfferModal .email-phase4Offer-popup-signature {
    font-style: italic; }
  .modal.Phase4OfferModal .phase4Offer-popup-other-message,
  .modal.Phase4OfferModal .email-phase4Offer-body,
  .modal.Phase4OfferModal .email-phase4Offer-popup-signature,
  .modal.Phase4OfferModal h1 {
    width: 90%; }

.SectionsMenu {
  display: flex;
  height: 3rem; }
  .SectionsMenu .chapter {
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 28px;
    line-height: 33px;
    color: #2764FF; }
  .SectionsMenu .menu {
    margin-left: 6rem;
    display: flex; }
    .SectionsMenu .menu .menu-item {
      text-align: left;
      box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      min-width: 19rem;
      font-family: 'Rubik', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #ccc;
      background: white;
      margin-right: 1rem;
      padding: 0.5rem 1rem; }
      .SectionsMenu .menu .menu-item.active {
        color: #fff;
        background: #2764FF; }

@media screen and (min-width: 601px) and (max-width: 1199px) {
  .SectionsMenu .chapter {
    font-size: 22px; } }

.Main {
  display: flex;
  flex-grow: 1;
  flex-basis: 10px;
  padding: 1rem 3.75rem 0 3.75rem; }
  .Main > .content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 100px;
    min-width: 0;
    max-height: calc(100vh - 190px); }

@media screen and (min-width: 601px) and (max-width: 1199px) {
  .Main {
    flex-grow: 1;
    flex-basis: unset; }
    .Main > .content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      flex-basis: 100px;
      min-width: 0;
      max-height: none; } }

.WelcomeMessageWithLogout {
  font-family: 'Rubik', sans-serif;
  color: #2764FF;
  font-style: italic;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  flex-direction: row;
  text-align: right;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem 3.75rem 0 3.75rem; }
  .WelcomeMessageWithLogout .button-empty-blu {
    margin-left: 1.9rem;
    text-transform: uppercase;
    font-style: normal;
    font-size: 14px;
    padding: 0 16px;
    line-height: 29px; }

.ReportPreview {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 2;
  background: #fff;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin: 0.5rem;
  max-width: 20%; }
  .ReportPreview > a {
    width: 100%;
    display: block;
    text-align: center; }
    @media screen and (max-height: 670px) {
      .ReportPreview > a {
        margin-bottom: 0; } }
    .ReportPreview > a.image-link {
      width: 70%;
      margin-bottom: 2rem; }
    .ReportPreview > a > .image {
      object-fit: contain;
      width: 80%;
      margin: 0 auto; }
      @media screen and (max-height: 710px) {
        .ReportPreview > a > .image {
          display: none; } }

.ThankYou {
  height: calc(100vh - 100px);
  background: radial-gradient(790.87px at 58.69% 39.44%, #2764FF 0%, #003a69 100%);
  overflow-y: auto;
  text-align: left;
  color: #fff;
  position: relative;
  padding: 5rem 7rem 7rem 7rem; }
  .ThankYou .WelcomeHeader, .ThankYou .ThankYou-message {
    display: inline-block;
    width: 50%; }
  .ThankYou .WelcomeHeader {
    vertical-align: top; }
  .ThankYou .ThankYou-message {
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-size: 2.5rem;
    line-height: 43px;
    text-align: right;
    font-weight: 100; }
    .ThankYou .ThankYou-message b {
      font-weight: 500; }
  .ThankYou .greetings-label {
    font-size: 2.5rem; }
  .ThankYou .eurofire-knows-message {
    line-height: 1rem; }
    .ThankYou .eurofire-knows-message small {
      font-size: 55%; }
  .ThankYou .report-container, .ThankYou .llabLogo-container {
    text-align: right; }
  .ThankYou .report-container {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: 2rem; }
  .ThankYou .llabLogo-container {
    position: absolute;
    right: 7rem;
    bottom: 4rem; }
  .ThankYou .teacherInfoBoxes-container {
    position: absolute;
    right: 5rem;
    bottom: 10rem; }

.ThankYouImage {
  position: fixed;
  left: 0;
  width: 40%;
  bottom: 0; }

/* reset css input */
input {
  border: none;
  background-image: none;
  background-color: transparent;
  box-shadow: none; }

